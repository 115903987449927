@keyframes shadow {
  0% {
    background-color: rgba(255, 255, 255, 0.8);
  }
  // 10% {
  //   background-color: rgba(255, 255, 255, 0.8);
  // }
  100% {
    background-color: rgba(255, 255, 255, 0);
  }
}
.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999999;
  opacity: 1;
  pointer-events: none;
  .shadow {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation: shadow 0.8s ease forwards;
  }
}
