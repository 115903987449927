.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modalContainer {
  background: #ffffff;
  border-radius: 16px;
  width: 90%;
  max-width: 400px;
  padding: 24px;
  position: relative;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
}

.closeButton {
  position: absolute;
  top: 12px;
  right: 12px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
}

.imageContainer {
  margin-bottom: 30px;
}

.content h1 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
  line-height: 1.4;
  
  @media screen and (max-width: 760px) {
    font-size: 16px;
  }
}

.content p {
  font-size: 14px;
  font-weight: bold;
  color: #666;
  line-height: 1.6;
  margin-bottom: 24px;
  
  @media screen and (max-width: 760px) {
    font-size: 12px;
  }
}

.content span {
  color: #6b2a59;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  vertical-align: baseline;
}

.niceAlarmContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.niceAlarm {
  max-width: 60%;
  height: auto;
  object-fit: contain;
}

.authenticateButton {
  background-color: #6b2a59;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  margin-top: 16px;

  &:hover {
    // background-color: #e56c00;
  }
  
  @media screen and (max-width: 760px) {
    font-size: 13px;
  }
}
