.askWrap {
  display: flex;
  padding: 19px 12px 19px 10px;
  cursor: pointer;
  &.open {
    & > p {
      font-weight: 700;
    }
    img {
      transform: rotate(180deg);
    }
  }
  .qMark {
    font-weight: 700;
    font-size: 21px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: -0.5px;
    color: var(--theme-font-color);
    margin-right: 10px;
  }
  & > p {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: var(--theme-font-color);
  }
  & > img {
    width: 24px;
    height: 24px;
    margin-left: auto;
  }
}
.answerWrap {
  display: flex;
  align-items: flex-start;
  padding: 23px 46px 23px 10px;
  background-color: #f5f6f7;
  .aMark {
    font-weight: 700;
    font-size: 21px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.5px;
    color: var(--theme-font-color);
    margin-right: 10px;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: var(--theme-font-color);
  }
}
