@keyframes openAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.container {
  position: fixed;
  top: 0;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  z-index: 999;
  width: 100%;
  max-width: 850px;
  background: rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(10px);
  animation: openAnimation 0.2s linear forwards;
}
.top {
  // padding: 0 21px 0 58px;
  height: 48px;
  display: flex;
  align-items: center;
  background: var(--theme-color);
  .logo {
    width: 100px;
    height: 25px;
    margin-left: 58px;
  }
  form {
    position: relative;
    margin: auto;
    cursor: pointer;
    input {
      width: 386px;
      height: 32px;
      border: none;
      outline: none;
      box-sizing: border-box;
      background-color: transparent;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: white;
      padding: 0 40px 0 10px;
      &::placeholder {
        color: #6e6d79;
      }
    }
    img {
      position: absolute;
      top: 50%;
      right: 13px;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
    }
  }
  .closeBtn {
    margin-left: auto;
    margin-right: 21px;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #ffffff;
    display: inline-block;
    padding: 0 21px;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.searchRecordWrap {
  width: 460px;
  height: 233px;
  background-color: white;
  margin: 0 auto;
  border-radius: 0px 0px 16px 16px;
}
