.heighter {
  height: 181px;
  transition: 0.2s linear;
  &.smaller {
    height: 49px;
  }
}
:global .pc {
  .container {
    min-width: 850px;
  }
}
.container {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 850px;
  transition: 0.2s linear;
  z-index: 100;
  .eventHeader {
    text-align: right;
    position: relative;
    cursor: pointer;
    a {
      position: absolute;
      top: 30%;
      right: 5%;
      button {
        color: #fff;
        font-size: 12px;
        font-weight: 600;
        background-color: var(--theme-color);
        border: none;
        cursor: pointer;
      }
      img {
        margin-left: 5px;
        width: 14px;
        height: 14px;
      }
    }
  }
  .header {
    display: flex;
    align-items: center;
    background-color: var(--theme-color);
    background-color: #fff;
    height: 70px;
    position: relative;
    .menus {
      height: 100%;
      display: flex;
      background-color: white;
      & > li {
        padding: 0 20px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--theme-font-color);
        white-space: nowrap;
        cursor: pointer;
        z-index: 1;
        ul {
          display: none;
          width: 100%;
          height: 48px;
          position: absolute;
          left: 0;
          bottom: 0;
          transform: translate(0, 100%);
          justify-content: center;
          align-items: center;
          gap: 24px;
          border-top: 1px solid #efefef;
          border-bottom: 1px solid #efefef;
          background-color: white;
          li {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 86px;
            height: 37px;
            flex-shrink: 0;
            font-weight: 500;
            font-size: 14px;
            line-height: normal;
            color: var(--theme-font-color);
          }
        }
      }
      .productMenu {
        &:hover ul {
          display: flex;
        }
      }
      .communityMenu {
        &:hover ul {
          display: flex;
        }
      }
      li:hover {
        color: var(--theme-color);
      }
      .movingBar {
        position: absolute;
        bottom: 0;
        width: 0px;
        height: 3px;
        background: var(--theme-color);
        transition: all 0.2s linear;
      }
    }
    .logoWrap {
      margin-right: 20px;
    }
    .logo {
      width: 89px;
      height: 18px;
      object-fit: contain;
      cursor: pointer;
      // margin: auto;
    }
    .searchForm {
      margin-left: 54px;
      position: relative;
      cursor: pointer;
      input {
        width: 280px;
        height: 40px;
        background: #f8f9fd;
        border-radius: 16px;
        box-sizing: border-box;
        border: none;
        outline: none;
        padding-left: 19px;
        &::placeholder {
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          letter-spacing: 0.004em;
          color: #abb1bb;
        }
      }
      img {
        position: absolute;
        top: 50%;
        right: 17px;
        transform: translateY(-50%);
        width: 22px;
        height: 22px;
      }
    }
    .btns {
      margin-left: auto;
      display: flex;
      // gap: 16px;
      li {
        padding: 0 16px;
        position: relative;
        &:last-child::after {
          display: none;
        }
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          width: 1px;
          height: 12px;
          background-color: #d9d9d9;
        }
        a {
          display: inline-block;
          padding: 4px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #666;
          &:last-child {
            padding: 4px 0;
          }
        }
      }
    }
  }
}
.container.tiny {
  top: -50px;
}
.container.relationTiny {
  top: 50px;
  // top: 0;
}
.container.mainTiny {
  top: 0px;
}
.mEventHeader {
  cursor: pointer;
  position: fixed;
  width: 100%;
  max-width: 850px;
  height: 50px;
  background-color: var(--theme-color);
  display: flex;
  justify-content: space-between;
  z-index: 100;
  .mEventBox {
    padding: 8px 12px;
    @media screen and (max-width: 280px) {
      padding: 13px 12px;
    }
    img {
      width: 136px;
      height: 35px;
      margin-right: 15px;
      @media screen and (max-width: 360px) {
        margin-right: 2px;
      }
      @media screen and (max-width: 280px) {
        width: 100px;
        height: 30px;
        margin-right: 2px;
      }
    }
    span {
      color: #fff;
      font-size: 12px;
      font-weight: 600;
      @media screen and (max-width: 280px) {
        font-size: 10px;
      }
    }
  }
  .mEventDirect {
    padding: 13px 24px 13px 0px;
    @media screen and (max-width: 280px) {
      padding: 16px 8px 16px 0px;
    }
    img {
      width: 14px;
      height: 14px;
    }
  }
}
.mContainerY {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 21px;
  height: 62px;
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  max-width: 850px;
  top: 0;
  left: 50%;
  z-index: 100;
  background-color: #fff;
  transform: translateX(-50%);
  .logo {
    width: 89px;
    height: 18px;
  }
  .btns {
    display: flex;
    gap: 15px;
    .basket {
      width: 17px;
      height: 20px;
    }
    img {
      width: 20px;
      height: 20px;
    }
  }
}

.mRelationHit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 21px;
  height: 62px;
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  max-width: 850px;
  top: -50px;
  left: 50%;
  z-index: 100;
  background-color: #fff;
  transform: translateX(-50%);
  .logo {
    width: 89px;
    height: 18px;
  }
  .btns {
    display: flex;
    gap: 15px;
    .basket {
      width: 17px;
      height: 20px;
    }
    img {
      width: 20px;
      height: 20px;
    }
  }
}

.mRelation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 21px;
  height: 62px;
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  max-width: 850px;
  top: 50px;
  left: 50%;
  z-index: 100;
  background-color: #fff;
  transform: translateX(-50%);
  .logo {
    width: 89px;
    height: 18px;
  }
  .btns {
    display: flex;
    gap: 15px;
    .basket {
      width: 17px;
      height: 20px;
    }
    img {
      width: 20px;
      height: 20px;
    }
  }
}

.mContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 21px;
  height: 62px;
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  max-width: 850px;
  top: 50px;
  left: 50%;
  z-index: 100;
  background-color: #fff;
  transform: translateX(-50%);
  .logo {
    width: 89px;
    height: 18px;
  }
  .btns {
    display: flex;
    gap: 15px;
    .basket {
      width: 17px;
      height: 20px;
    }
    img {
      width: 20px;
      height: 20px;
    }
  }
}

.basketCntWrap {
  position: absolute;
  right: 45px;
  top: 18px;
  width: 20px;
  height: 20px;
  background: var(--theme-color);
  border-radius: 50%;
  color: #fff;
  font-weight: 600;
  font-size: 10px;
  line-height: normal;
  line-height: 20px;
  text-align: center;
}
.mHeighter {
  height: 112px;
  &.mainHeight {
    height: 62px;
  }
}
