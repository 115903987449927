.container {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, calc(100% - 1px));
    z-index: 1;
    border: 1px solid #e5e5e5;
    background-color: white;
    width: 100%;
    min-width: 300px;
    box-sizing: border-box;
}
.calendarWrap {
    // border-bottom: 15px solid #f6f7f9;
}
.calendar {
    padding: 20px 31px;
    width: 100%;
    max-width: 360px;
    border: none;
    :global(.react-calendar__navigation) {
        margin-bottom: 0;
    }
    :global(.react-calendar__navigation__label__labelText) {
        //년월
        color: #232323;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: -0.5px;
        font-family: Pretendard;
    }
    :global(.react-calendar__month-view__weekdays) {
        //요일
        color: #131c31;
        text-align: center;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.5px;
        padding: 10px 0;
        border-bottom: 1px solid #e5e5e5;
        // justify-content: space-between;
        margin-bottom: 11px;
        :global(.react-calendar__month-view__weekdays__weekday) {
            // flex: unset !important;
            padding: 0;
            &:first-child {
                color: #dc0000;
            }
        }
    }
    :global(.react-calendar__month-view__days) {
        //일
        :global(.react-calendar__tile--now) {
            //오늘
            background-color: unset;
        }
        :global(.react-calendar__tile) {
            aspect-ratio: 1/1;
            color: #131c31;
            font-family: Pretendard;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.5px;
            display: flex;
            justify-content: center;
            align-items: center;
            &:global(.sunday),
            &:global(.holiday) {
                color: #dc0000;
            }
            &:global(.past),
            &:global(.today),
            &:global(.tomorrow) {
                color: #f0f0f0;
            }
        }
        :global(.react-calendar__tile:disabled) {
            background-color: unset;
            // color: #f0f0f0;
        }
        :global(.react-calendar__tile--active) {
            background-color: #131c31;
            color: white !important;
            border-radius: 50%;
        }
    }
}
.timeWrap {
    border-top: 15px solid #f6f7f9;
    padding: 20px 32px 40px;
    .header {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        height: 37px;
        border-bottom: 1px solid #e5e5e5;
        svg {
            position: absolute;
            top: 0;
            left: 0;
        }
        h6 {
            color: #232323;
            text-align: center;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: -0.5px;
        }
    }
    .times {
        .morning,
        .afternoon {
            margin-top: 20px;
            h6 {
                color: rgba(19, 28, 49, 0.5);
                font-size: 14px;
                font-weight: 400;
                letter-spacing: -0.5px;
                margin-bottom: 8px;
            }
            ul {
                display: grid;
                gap: 8px;
                grid-template-columns: repeat(4, 1fr);
                li {
                    border-radius: 5px;
                    border: 1px solid #d1d0d5;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 8.5px 0;
                    cursor: pointer;
                    color: #131c31;
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: -0.5px;
                    position: relative;
                    &.lunch::after {
                        content: "점심시간";
                        display: block;
                        position: absolute;
                        width: 100%;
                        bottom: 0;
                        text-align: center;
                        font-size: 10px;
                    }
                    &.disable {
                        background-color: #f0f0f0;
                        color: rgba(16, 16, 16, 0.3);
                        cursor: default;
                    }
                    &.hit {
                        background: #131c31;
                        color: #fff;
                        border: none;
                    }
                }
            }
        }
    }
}
