.bannerSliderWrap {
  padding: 32px 21px 60px 21px;
  position: relative;
  .bannerSlider {
    // border-radius: 16px;
    overflow: hidden;
    img {
      width: 100%;
      // aspect-ratio: 1/1.2;
      object-fit: cover;
    }
  }
  .paginationWrap {
    position: absolute;
    bottom: 75px;
    left: 42px;
    display: flex;
    gap: 4px;
    z-index: 1;
    li {
      width: 8px;
      height: 8px;
      background-color: white;
      border-radius: 4px;
      transition: width 0.2s linear;
      cursor: pointer;
      &.hit {
        width: 24px;
      }
    }
  }
}
.processInfoWrap {
  background-size: cover;
  padding: 64px 0px;
  & > h3 {
    padding: 10px 21px;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: var(--theme-font-color);
    text-align: center;
  }
  & > p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: var(--theme-font-color);
    padding: 4px 21px;
    text-align: center;
  }
  & > ul {
    display: flex;
    column-gap: 13px;
    row-gap: 12px;
    flex-wrap: wrap;
    margin-top: 19px;
    padding: 0 21px;
    li {
      background: #f8f9fd;
      border: 1px solid #e1e6ef;
      border-radius: 100px;
      padding: 8px 18px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.05em;
      color: #141736;
    }
  }
  .processSliderWrap {
    position: relative;
    .processSlider {
      margin-top: 32px;
      padding: 0 21px;

      .processSlide {
        background: #f8f9fd;
        border-radius: 16px;
        box-sizing: border-box;
        aspect-ratio: 1/1.06;
        padding-top: 39px;
        padding-bottom: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        & > img {
          width: 145px;
          height: 132px;
        }
        .stage {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 32px;
          span {
            font-weight: 700;
            font-size: 32px;
            line-height: 38px;
            letter-spacing: -0.5px;
            color: #1c1c1c;
          }
          h4 {
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            letter-spacing: -0.5px;
            color: var(--theme-font-color);
            padding: 10px 0;
          }
          p {
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: -0.5px;
            color: var(--theme-font-color);
            padding: 4px 0;
          }
        }
      }
    }
    .processSliderPagination {
      position: absolute;
      bottom: 75px;
      left: 50%;
      transform: translateX(-50%);
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      li {
        width: 12px;
        height: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        div {
          width: 4px;
          height: 4px;
          background-color: var(--theme-color);
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        &::after {
          transition: all 0.2s;
          scale: 0;
          content: "";
          display: block;
          width: 8px;
          height: 8px;
          border: 1px solid var(--theme-color);
          box-sizing: border-box;
          border-radius: 50%;
          transform-origin: center center;
        }
        &.hit::after {
          scale: 1;
        }
      }
    }
  }
}
.prescriptionProductWrap {
  padding: 0 21px 60px 21px;
  & > h3 {
    display: flex;
    align-items: center;
    padding: 10px 0;
    width: fit-content;
    cursor: pointer;
    span {
      font-weight: 700;
      font-size: 24px;
      line-height: normal;
      color: var(--theme-font-color);
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
  & > span {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.004em;
    color: var(--theme-font-color);
    display: inline-block;
    padding-bottom: 10px;
  }
  .prescriptionProductSlider {
    .prescriptionProductSlide {
      & > img {
        background: #fbfbfb;
        aspect-ratio: 333/206;
        width: 100%;
        object-fit: contain;
        border-radius: 10px;
      }
      .productInfoWrap {
        h3 {
          font-weight: 600;
          font-size: 20px;
          line-height: 20px;
          color: var(--theme-font-color);
          padding: 10px 0;
        }
        .hashtagList {
          li {
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: -0.004em;
            color: rgba(19, 28, 49, 0.5);
          }
        }
      }
    }
  }
  .productSlideWrap {
    cursor: pointer;
    .productThumbnailWrap {
      img {
        background: #fbfbfb;
        border-radius: 10px;
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
      }
    }
    .productInfoWrap {
      padding: 16px;
      .productInfoLeft {
        p {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
        .productInfoPrice {
          display: flex;
          gap: 4px;
          align-items: center;
          span:first-child {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
          }
          span:nth-child(2) {
            font-weight: 600;
            font-size: 10px;
            color: #999;
            text-decoration: line-through;
          }
        }
        .productInfoScore {
          display: flex;
          gap: 3px;
          align-items: center;
          img {
            width: 14px;
            height: 14px;
          }
          span {
            font-size: 12px;
          }
        }
      }
    }
  }
}
.normalProductWrap {
  padding: 32px 21px;
  & > h3 {
    display: flex;
    align-items: center;
    padding: 10px 0;
    width: fit-content;
    cursor: pointer;

    span {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: var(--theme-font-color);
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
  & > span {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.004em;
    color: var(--theme-font-color);
    display: inline-block;
    padding-bottom: 10px;
  }
  .normalProductList {
    display: flex;
    margin-top: 16px;
    li {
      padding-bottom: 14px;
      width: 100%;
      .productThumbnailWrap {
        width: 100%;
        border: 1px solid #e5e5e5;
        border-radius: 5px;
        overflow: hidden;
        aspect-ratio: 1/0.57;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 148px;
          aspect-ratio: 1/1;
          object-fit: cover;
        }
      }
      .productInfoWrap {
        h3 {
          font-weight: 700;
          font-size: 15px;
          line-height: 18px;
          letter-spacing: -0.004em;
          color: var(--theme-font-color);
          padding: 10px 0;
        }
        .hashtagList {
          padding-bottom: 10px;
          li {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: -0.004em;
            color: rgba(19, 28, 49, 0.5);
          }
        }
      }
    }
  }
}
.allProductList {
  padding: 0 21px 32px;
  & > h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: var(--theme-font-color);
    padding: 10px 0;
  }
  & > span {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.004em;
    color: var(--theme-font-color);
    display: inline-block;
    padding-bottom: 10px;
  }
  .productListWrap {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    li {
      width: calc(50% - 10px);
      padding-bottom: 20px;
      .productThumbnailWrap {
        width: 100%;
        border: 1px solid #e5e5e5;
        border-radius: 5px;
        overflow: hidden;
        img {
          width: 100%;
          aspect-ratio: 1/1.2;
          object-fit: cover;
        }
      }
      .productInfoWrap {
        display: flex;
        flex-direction: column;
        & > h3 {
          font-weight: 700;
          font-size: 15px;
          line-height: 18px;
          letter-spacing: -0.004em;
          color: var(--theme-font-color);
          padding: 10px 0;
        }
        .discountRate {
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: -0.05em;
          color: #ff5656;
          display: inline-block;
          padding: 0.5px 0;
        }
        .price {
          font-weight: 700;
          font-size: 15px;
          line-height: 18px;
          letter-spacing: -0.05em;
          color: var(--theme-font-color);
          display: inline-block;
          padding: 1px 0;
        }
        .reviewScore {
          display: flex;
          align-items: center;
          gap: 3px;
          height: 20px;
          img {
            width: 14px;
            height: 14px;
          }
          span {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: -0.05em;
            color: var(--theme-font-color);
          }
        }
      }
    }
  }
  button {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: rgba(19, 28, 49, 0.5);
    margin: 0 auto;
    border: 1px solid #e5e5e5;
    background-color: white;
    border-radius: 45.5px;
    width: 157px;
    height: 50px;
    display: block;
    margin-top: 32px;
  }
}
.faqWrap {
  padding: 32px 21px 81px 21px;
  background: #f6f4f8;
  margin-bottom: 60px;
  & > h3 {
    display: flex;
    align-items: center;
    padding: 10px 0;
    width: fit-content;
    cursor: pointer;
    span {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: var(--theme-font-color);
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
  & > span {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.004em;
    color: var(--theme-font-color);
    display: inline-block;
    padding-bottom: 10px;
  }
  & > ul {
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
.reviewWrap {
  margin-bottom: 60px;
  & > h3 {
    display: flex;
    align-items: center;
    padding: 10px 0 10px 21px;
    width: fit-content;
    cursor: pointer;
    span {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: var(--theme-font-color);
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
  & > span {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.004em;
    color: var(--theme-font-color);
    display: inline-block;
    padding-bottom: 10px;
    padding-left: 21px;
  }
  .reviewSliderWrap {
    min-height: 200px;
    margin-top: 32px;
    padding-left: 21px;
    position: relative;
    border-radius: 0;
  }
}
.legitScript {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  position: relative;

  & > img:first-child {
    width: 100%;
    aspect-ratio: 1/0.74;
    object-fit: cover;
  }
  & > div {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img:first-child {
      width: 80px;
      height: 79px;
      object-fit: cover;
    }
    p:nth-child(2) {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #ffffff;
      padding: 17px 0 10px;
    }
    p:last-child {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      color: #ffffff;
    }
  }
}
.ondocInfoWrap {
  position: relative;
  background-color: #fff;
  margin-bottom: 60px;
  .ondocInfoPaginationWrap {
    // position: absolute;
    // right: 30px;
    // bottom: 10px;
    // z-index: 1;
    // background-color: rgba(0, 0, 0, 0.2);
    // color: #fff;
    // padding: 5px 8px;
    // border-radius: 20px;
    // font-size: 12px;
    position: absolute;
    right: 30px;
    bottom: 10px;
    display: flex;
    gap: 4px;
    z-index: 1;
    li {
      width: 8px;
      height: 8px;
      background-color: #fff;
      border-radius: 4px;
      transition: width 0.2s linear;
      cursor: pointer;
      background: var(--theme-color);
      &.hit {
        background: #fff;
        background: var(--theme-color);
        width: 20px;
      }
    }
  }
  .infoSlider {
    position: relative;
    .handleBtn {
      position: absolute;
      left: 0px;
      bottom: 170px;
      bottom: 50px;
      width: 30px;
      height: 30px;
      z-index: 1;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
      &.rightHandleBtn {
        left: unset;
        right: 0px;
      }
    }
    .slide1,
    .slide2,
    .slide3 {
      position: relative;
      display: flex;
      gap: 25px;
      flex-direction: column;
      width: 100%;
      padding: 0 0 41px 0;
      background: #fff;
      background-color: #f6f4f8;
      h3 {
        padding: 40px 0 0 32px;
        font-weight: 700;
        font-size: 26px;
        line-height: 38px;
        color: #fff;
        color: var(--theme-color);
      }
      img {
        position: absolute;
        object-fit: cover;
        width: 100%;
      }
      p {
        padding: 0 0 0 32px;
        font-weight: 500;
        font-size: 15px;
        line-height: 19px;
        letter-spacing: -0.7px;
        color: var(--theme-font-color);
      }
    }
    .slide1 {
      img {
        top: 32px;
        right: 40px;
        width: 74px;
        height: 92px;
      }
    }
    .slide2 {
      img {
        top: 24px;
        right: 25px;
        width: 100px;
        height: 100px;
      }
    }
    .slide3 {
      img {
        top: 24px;
        right: 25px;
        width: 85px;
        height: 85px;
      }
    }
  }
}
.doctorInfoWrap {
  background: #fff;
  background-size: cover;
  padding: 20px 0px 43px;
  & > h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    padding: 10px 21px;
  }
  & > p {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    padding: 4px 21px;
  }

  .doctorSliderWrap {
    position: relative;
    .doctorSlider {
      border: 1px solid #dddddd;
      border-radius: 20px;
      margin: 32px 10px 0 10px;
      .doctorSlide {
        position: relative;
        background: #ffffff;
        display: flex;
        align-items: center;
        .doctorImageWrap {
          // width: 100%;
          max-width: 224px;
          max-height: 230px;
          aspect-ratio: 1/1.3;
          overflow: hidden;
          position: absolute;
          right: -25px;
          bottom: 0;
          & > img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: top;
            &.bigImage {
              object-position: top;
            }
          }
        }
        .doctorInfo {
          padding: 0 0 0 21px;
          width: 100%;
          & > h4 {
            font-weight: 800;
            font-size: 20px;
            line-height: 27px;
          }
          & > span {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            display: inline-block;
            padding-bottom: 2px;
            width: 100%;
            margin-bottom: 21px;
          }
          & > ul {
            margin: 20px 0;
            display: flex;
            flex-direction: column;
            width: 100%;
            box-sizing: border-box;
            height: 150px;
            li {
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              color: var(--theme-font-color);
            }
          }
        }
      }
    }
  }
  .doctorPaginationWrap {
    position: absolute;
    right: 30px;
    bottom: 10px;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    padding: 4px 7px;
    border-radius: 20px;
    font-size: 12px;
  }
}
.ondocService {
  background: url("../../assets/ondocService.png");
  aspect-ratio: 375/366;
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 60px;
  .bigText {
    font-weight: 600;
    font-size: 28px;
    line-height: 38px;
    color: var(--theme-color);
  }
  .smallText {
    font-weight: 300;
    font-size: 17px;
    line-height: 24px;
    color: #131c31;
  }
}

.serviceInfoWrap {
  padding: 0 0 20px;
  text-align: center;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
}
