.container {
  background-color: #f6f7f9;
  padding: 0 20px;
}
.title {
  padding: 42px 12px;
  img {
    width: 24px;
    height: 24px;
  }
  span {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #232323;
  }
}

.addCouponForm {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 13px 0;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  label {
    display: flex;
    align-items: center;
    flex-shrink: 1;
    span {
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      color: var(--theme-font-color);
      padding: 10px;
      flex-shrink: 0;
    }
    input {
      background: #ffffff;
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      height: 38px;
      box-sizing: border-box;
      padding-left: 10px;
      flex-shrink: 1;
      min-width: 0;
      &::placeholder {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: rgba(19, 28, 49, 0.5);
      }
    }
  }
  button {
    background: var(--theme-color);
    border-radius: 5px;
    box-sizing: border-box;
    width: 69px;
    height: 38px;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #ffffff;
    border: none;
    cursor: pointer;
    flex-shrink: 0;
  }
}

.usableCouponListWrap,
.unusableCouponListWrap {
  padding: 32px 0 0;
  h4 {
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: var(--theme-font-color);
    padding: 20px 0;
    display: flex;
    align-items: center;
    gap: 2px;
    span {
      color: #4d7eff;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
    }
  }
  .usableCouponList,
  .unusableCouponList {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 36px;
    row-gap: 32px;
    li {
      height: 100px;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 8px;
      span:first-child {
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        color: var(--theme-font-color);
      }
      span:last-child {
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: rgba(19, 28, 49, 0.5);
      }
    }
    &.unusableCouponList li {
      span {
        color: rgba(19, 28, 49, 0.5);
      }
    }
  }
}

.couponGuide {
  margin-top: 81px;
  padding-bottom: 64px;
  h4 {
    height: 36px;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    color: rgba(19, 28, 49, 0.5);
    margin-bottom: 10px;
  }
  ul li {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(19, 28, 49, 0.5);
  }
}

:global(.pc) .usableCouponList,
:global(.pc) .unusableCouponList {
  grid-template-columns: repeat(2, 1fr);
}
