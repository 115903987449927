.contentWrap {
  background: #f6f7f9;
  min-height: 500px;
  .title {
    text-align: center;
    padding: 42px 21px;
    img {
      width: 21px;
      height: 21px;
    }
    span {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #232323;
    }
    &.isMobile {
      text-align: left;
    }
  }
}
@media screen and (max-width: 333px) {
  .myCardsWrap {
    padding: 0 21px;
    box-sizing: border-box;
  }
}
.myCardsWrap {
  width: 100%;
  max-width: 333px;
  margin: 0 auto;
  & > h4 {
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: var(--theme-font-color);
    padding: 20px 0;
    span {
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      color: #4d7eff;
      padding-bottom: 2px;
      display: inline-block;
    }
  }
}
.cardList {
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-bottom: 18px;
  .card {
    position: relative;
    background-color: white;
    border: 1px solid rgba(19, 28, 49, 0.2);
    border-radius: 5px;
    box-sizing: border-box;
    aspect-ratio: 1/0.43;
    display: flex;
    justify-content: center;
    align-items: center;
    .cardDelete {
      position: absolute;
      top: 0;
      right: 0;
      padding: 10px;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      color: var(--theme-font-color);
      cursor: pointer;
    }
    .cardInfo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h5 {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: var(--theme-font-color);
        padding: 2px;
      }
      span {
        display: inline-block;
        padding: 2px;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: var(--theme-font-color);
      }
    }
  }
}

.appendCardBtn {
  background: var(--theme-color);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 56px;
  cursor: pointer;
  img {
    width: 24px;
    height: 24px;
  }
  span {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
  }
}
