.container {
  header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 50px;
    background-color: #f6f7f9;
    .backBtn {
      position: absolute;
      top: 50%;
      left: 21px;
      transform: translateY(-50%);
      width: 21px;
      height: 21px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        transform: rotate(180deg);
      }
    }
    h4 {
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: -0.5px;
      color: #232323;
    }
  }
  .eventTitle {
    display: flex;
    align-items: center;
    height: 60px;
    background-color: #f6f7f9;
    padding: 0 21px;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    .isProgressing {
      padding: 4px 15px;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: -0.5px;
      color: white;
      background-color: #121842;
      border-radius: 100px;
      margin-right: 8px;
      &.isOff {
        background-color: #999999;
      }
    }
    span {
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: -0.5px;
      color: #232323;
    }
  }
  .eventInfo {
    height: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #f6f7f9;
    padding: 0 21px;
    gap: 11px;
    span {
      font-size: 10px;
      font-weight: 400;
      line-height: 12px;
      letter-spacing: -0.5px;
      color: #cccccc;
      position: relative;
      &:first-child::after {
        position: absolute;
        right: -6px;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        display: block;
        width: 1px;
        height: 7px;
        background-color: #cccccc;
      }
    }
  }
  .contentWrap {
    font-size: 12px;
    font-weight: 300;
    line-height: 14px;
    letter-spacing: -0.5px;
    color: #000000;
    img {
      display: block;
      max-width: 100%;
      margin: 0 auto;
    }
  }
}
