.container {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  // max-width: 850px;
  width: 100%;
  background-color: white;
  z-index: 999;
  &.isPc {
    min-width: 850px;
    ul {
      justify-content: center;
      // gap: 139px;
      gap: 100px;
      li {
        width: 73px;
      }
    }
  }
  .safeArea {
    height: env(safe-area-inset-bottom);
  }
  ul {
    display: flex;
    height: 64px;
    border-top: 1px solid #efefef;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    @media screen and (min-width: 768px) {
      padding: 0 35px;
    }
    @media screen and (max-width: 290px) {
      padding: 0;
    }
    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 4px;
      width: 65px;
      height: 100%;
      cursor: pointer;
      &.hit span {
        font-weight: 700;
      }
      img {
        width: 24px;
        height: 24px;
      }
      span {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: var(--theme-font-color);
        white-space: nowrap;
        @media screen and (max-width: 480px) {
          font-size: 10px;
        }
      }
    }
  }
}
:global(.pc) .container ul {
  padding: 0 94px;
}
