.contentWrap {
  width: 100%;
  aspect-ratio: 1/1.4;
  background: url('../../assets/companyInfoBg.jpg');
  background-size: cover;
  background-position: center;
  text-align: center;
  padding-top: 89px;
  box-sizing: border-box;
  img {
    margin-top: 56px;
    width: 177px;
    height: 29px;
    object-fit: contain;
  }
  p:nth-child(1) {
    font-weight: 700;
    font-size: 32px;
    line-height: 54px;
    text-align: center;
    letter-spacing: -0.5px;
    color: var(--theme-color);
    word-break: keep-all;
    margin-bottom: 40px;
  }
  p:nth-child(2) {
    font-size: 15px;
    line-height: 18px;
    font-weight: 300;
    text-align: center;
    letter-spacing: -0.5px;
    color: var(--theme-font-color);
    word-break: keep-all;
    padding: 0 20px;
  }
}

:global(.pc) .contentWrap {
  aspect-ratio: 850/691;
  padding-top: 145px;
  img {
    margin-top: 205px;
  }
  p:nth-child(2) {
    font-size: 24px;
    line-height: 36px;
    padding: 0;
  }
}
