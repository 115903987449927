.container {
  background-color: #f6f7f9;
}
.top {
  background-color: #f6f7f9;
  border-bottom: 1px solid #eeeeee;
  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: #232323;
    padding: 13px 0 13px 22px;
    border-bottom: 1px solid #eeeeee;
  }
  .serviceInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    & > span:first-child {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.5px;
      color: #999999;
    }
    & > h4 {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: -0.5px;
      color: #232323;
      margin-top: 7px;
    }
    & > span:nth-child(3) {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.5px;
      color: #232323;
      padding: 7px 20px;
      background: #eeeeee;
      border: 1px solid #e5e5e5;
      border-radius: 100px;
      margin-top: 8px;
    }
    .serviceTimeInfo {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2px;
      margin-top: 11px;
      & > span {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.5px;
        color: #999999;
      }
    }
  }
}
.categoryListWrap {
  padding: 20px;
  border-bottom: 1px solid #efefef;
  margin-bottom: 10px;
  background-color: white;
  h4 {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: #232323;
    padding: 3px 0;
  }
  .categoryList {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    li {
      padding: 9px 22px;
      background: #f6f7f9;
      border-radius: 17.5px;
      cursor: pointer;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.5px;
      color: #232323;
      &.hit {
        background: #838a9b;
        color: white;
      }
    }
  }
}
.faqList {
  border-top: 1px solid #efefef;
  padding: 0 20px;
  background-color: white;
  min-height: 30vh;
  li {
    border-bottom: 1px solid #f5f6f7;
  }
}
.noData {
  text-align: center;
  color: #6e6d79;
  font-size: 18px;
  padding: 150px 0;
}
