.content {
  padding: 82px 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 333px;
  margin: 0 auto;
  img {
    width: 60px;
    height: 60px;
  }
  h2 {
    margin-top: 14px;
    padding: 10px 0;
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: -0.5px;
    color: var(--theme-font-color);
  }
  & > span {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: var(--theme-font-color);
    padding: 10px 0;
  }
  p {
    margin-top: 36px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: var(--theme-font-color);
    padding: 10px 0;
    text-align: center;
    span {
      color: #3259e6;
    }
  }
  button {
    margin-top: 48px;
    background: var(--theme-color);
    border: none;
    border-radius: 5px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #e5e5e5;
    height: 48px;
    width: 100%;
    cursor: pointer;
  }
}
