@keyframes bgAnimation {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.7);
  }
}
.container {
  z-index: 999999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: bgAnimation 0.3s linear forwards;
  display: flex;
  justify-content: flex-end;
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000;
  transform: translate3d(0, 0, 0);
  transform: translateZ(0);
  will-change: background-color;
}
@keyframes open {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}
.contentWrap {
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(7.5px);
  height: 100%;
  width: 321px;
  max-width: 100%;
  animation: open 0.3s linear forwards;
  position: relative;
  padding: 62px 21px 0;
  box-sizing: border-box;
  overflow: scroll;
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000;
  transform: translate3d(0, 0, 0);
  transform: translateZ(0);
  will-change: transform;
  &::-webkit-scrollbar {
    display: none;
  }
}
.closeBtn {
  position: absolute;
  top: 16px;
  left: 12px;
  width: 24px;
  height: 24px;
}
.userInfo {
  background: #ffffff;
  backdrop-filter: blur(2px);
  border-radius: 16px;
  display: flex;
  align-items: center;
  height: 66px;
  padding-left: 11px;
  .profileImage {
    // background: #d9d9d9;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  span {
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: var(--theme-font-color);
    display: inline-block;
    padding: 10px;
  }
}
.firstMenuWrap {
  background: #ffffff;
  backdrop-filter: blur(2px);
  border-radius: 16px;
  margin-top: 10px;
  padding: 20px 21px 118px;
  box-sizing: border-box;
  max-height: 433px;
  & > ul {
    display: flex;
    flex-direction: column;
    & > li {
      .title {
        height: 49px;
        display: flex;
        align-items: center;
        gap: 10px;
        span {
          font-weight: 700;
          font-size: 24px;
          line-height: 29px;
          color: var(--theme-font-color);
        }
        img {
          width: 18px;
          height: 18px;
        }
      }
      & > ul {
        max-height: 0px;
        overflow: hidden;
        transition: all 0.3s linear;
        &.productList.isOpen {
          max-height: 96px;
        }
        &.communityList.isOpen {
          max-height: 128px;
        }
        li {
          height: 32px;
          display: flex;
          align-items: center;
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          color: var(--theme-font-color);
        }
      }
    }
  }
}
.secondMenuWrap {
  margin-top: 14px;
  background: #ffffff;
  backdrop-filter: blur(2px);
  border-radius: 16px;
  height: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
  ul {
    display: flex;
    li {
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      color: var(--theme-font-color);
      padding: 10px 12px;
      // &:first-child {
      //   padding: 10px 16px;
      // }
      // &:nth-child(2) {
      //   padding: 10px 11px;
      // }
      // &:nth-child(3) {
      //   padding: 10px 9px;
      // }
      // &:nth-child(4) {
      //   padding: 10px 18px;
      // }
    }
  }
}
.ondoc {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: var(--theme-font-color);
  text-align: center;
  padding: 10px 0;
}
