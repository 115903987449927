.container {
  background-color: white;
  padding: 0 20px;

  & > div {
    max-width: 309px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    margin: 0 auto;
    padding: 143px 0 207px;
    h3 {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: var(--theme-color);
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #82899b;
    }
    img {
      max-width: 100%;
    }
    .openBtn {
      width: 100%;
      height: 56px;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
      background: var(--theme-color);
      border-radius: 10px;
      outline: none;
    }
    .keepBtn {
      border: none;
      outline: none;
      background-color: unset;
      font-size: 16px;
      line-height: 22px;
      color: #82899b;
      // text-decoration: underline;
    }
  }
}
