.container {
  position: fixed;
  top: 0;
  left: 50%;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.2);
  &.isDesktop {
    max-width: 850px;
    .contentWrap {
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 808px;
      height: 600px;
      border-radius: 0;
      &.resultContentWrap {
        .mainWrap {
          flex-direction: column;
          .leftWrap {
            padding: 50px 0 24px;
            width: 100%;
            .resultTextWrap {
              span {
                .hit {
                  bottom: 2px;
                }
              }
            }
          }
          .rightWrap {
            padding: 0 134px 32px;
            width: 100%;
          }
        }
        .resultBtnWrap {
          position: fixed;
          bottom: 0;
          left: 21px;
          padding: 16px 134px 42px;
          width: 100%;
          height: auto;
          background: #fff;
          .btnWrap {
            gap: 10px;
          }
        }
      }

      .closeWrap {
        position: absolute;
        right: 10px;
        top: -50px;
        img {
          cursor: pointer;
          width: 40px;
          height: 40px;
        }
      }
      .mainWrap {
        display: flex;
        max-height: 600px;
        height: 600px;
        overflow: auto;
        .leftWrap {
          padding: 60px 50px;
          background: #fff;
          width: 433px;
          box-sizing: border-box;
          justify-content: space-between;
          .bigTextWrap {
            span {
              font-weight: 600;
              font-size: 24px;
              line-height: 36px;
            }
          }
          .stepWrap {
            width: 333px;
          }
        }
        .rightWrap {
          padding: 21px 21px 0;
          width: 375px;
          height: 600px;
          box-sizing: border-box;
          overflow: scroll;
          overflow-x: hidden;
          .resultSubWrap {
            height: 100px;
          }
        }
      }
      .resultBtnWrap {
        padding: 0 21px 60px;
        background: #f6f7f9;
        position: relative;
        right: 0;
        width: 375px;
        height: auto;
        transform: translateX(-21px);
      }
    }
  }
  .contentWrap {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: calc(100vh - 80px);
    box-sizing: border-box;
    border-radius: 24px 24px 0 0;
    background: #f6f7f9;
    &.resultContentWrap {
      background: #fff;
      .mainWrap {
        .rightWrap {
          padding: 0 21px 34px;
        }
      }
    }
    .slideWrap {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 28px;
      img {
        width: 49px;
        height: 4px;
      }
    }
    .mainWrap {
      overflow: scroll;
      max-height: calc(100vh - 80px - 170px);
      .leftWrap {
        padding: 60px 30px 40px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        .bigTextWrap {
          padding: 0 12px;
          span {
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            .hit {
              position: relative;
              bottom: 1px;
              color: var(--theme-color);
            }
          }
        }
        .resultTextWrap {
          text-align: center;
          span {
            font-weight: 600;
            font-size: 36px;
            line-height: 44px;
            .hit {
              bottom: 4px;
            }
          }
        }
        .resultImgWrap {
          margin: 0 auto;
          max-width: 375px;
          img {
            width: 100%;
          }
        }
        .stepWrap {
          cursor: pointer;
          padding: 0 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 76px;
          box-sizing: border-box;
          border-radius: 5px;
          background: #ffecfa;
          .stepLeftWrap {
            display: flex;
            gap: 4px;
            flex-direction: column;
            & > span {
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
              color: var(--theme-color);
            }
            div {
              span {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #666;
              }
              img {
                width: 20px;
                height: 20px;
              }
            }
          }
          & > img {
            width: 47px;
            height: 44px;
          }
        }
      }
      .rightWrap {
        padding: 0 21px 21px;
        display: flex;
        gap: 21px;
        flex-direction: column;
        .resultSubWrap {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          height: 120px;
          background: #f6f7f9;
          border-radius: 16px;
          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            .hit {
              position: relative;
              bottom: 1px;
              font-weight: 600;
              color: var(--theme-color);
            }
          }
        }
        .stepFrameWrap {
          padding: 16px;
          display: flex;
          gap: 16px;
          flex-direction: column;
          border: 1px solid #d9d9d9;
          border-radius: 5px;
          background: #fff;
          &.warning {
            border: 1px solid #ff5656;
            .stepFrame {
              .titleWrap {
                color: #ff5656;
              }
            }
          }
          .stepFrame {
            display: flex;
            gap: 8px;
            flex-direction: column;
            &.warningFrame {
              .titleWrap {
                color: #ff5656;
              }
              .ansListWrap {
                .ansFrame {
                  border: 1px solid #ff5656;
                }
              }
            }
            .titleWrap {
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: #666;
            }
            .ansListWrap {
              display: flex;
              gap: 8px;
              flex-direction: column;
              .ansFrame {
                cursor: pointer;
                padding: 14px 16px;
                display: flex;
                align-items: center;
                gap: 12px;
                // height: 48px;
                border-radius: 5px;
                border: 1px solid #d9d9d9;
                box-sizing: border-box;
                &.hit {
                  border: 2px solid var(--theme-color);
                  background: #ffecfa;
                  .customRadioBox {
                    background: #fff;
                    border: 1.5px solid var(--theme-color);
                    &::after {
                      background-color: var(--theme-color);
                    }
                  }
                  span {
                    color: #131c31;
                  }
                }
                .customRadioBox {
                  flex-shrink: 0;
                  box-sizing: border-box;
                  width: 16px;
                  height: 16px;
                  background: white;
                  border: 1.5px solid #e5e5e5;
                  border-radius: 50%;
                  position: relative;
                  &::after {
                    content: '';
                    display: block;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  }
                }
                span {
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 20px;
                }
              }
            }
            .subWrap {
              padding: 4px 0;
              font-weight: 400;
              font-size: 11px;
              line-height: 16px;
              color: #999;
            }
          }
        }
      }
    }
    .resultBtnWrap {
      position: fixed;
      bottom: 0;
      padding: 16px 21px 10px;
      display: flex;
      gap: 16px;
      flex-direction: column;
      width: 100%;
      height: 142px;
      box-sizing: border-box;
      background: #fff;
      @media screen and (max-width: 360px) {
        height: auto;
        gap: 8px;
      }
      .alertTextWrap {
        text-align: center;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }
      .btnWrap {
        display: flex;
        gap: 9px;
        .reStepBtn,
        .resultBtn {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 52px;
          background: var(--theme-color);
          border-radius: 8px;
          span {
            font-weight: 600;
            font-size: 17px;
            line-height: normal;
            color: #fff;
          }
        }
        .reStepBtn {
          background: #ffecfa;
          span {
            color: var(--theme-color);
          }
        }
      }
    }
  }
}
