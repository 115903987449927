.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal {
  max-width: 280px;
  filter: drop-shadow(0px 2px 17px rgba(0, 0, 0, 0.4));
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .content {
    width: 100%;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
  .detailBtn {
    position: absolute;
    bottom: 60px;
    left: 0;
    width: 100%;
    aspect-ratio: 1/0.19;
    cursor: pointer;
    opacity: 0;
  }
  .closeBtn {
    cursor: pointer;
    width: 40px;
    height: 40px;
    background: none;
    border: none;
    margin-top: 20px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
