.container {
  background-color: #f6f7f9;
}
.header {
  padding-left: 21px;
  height: 50px;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  & > div {
    display: flex;
    align-items: flex-end;
    gap: 5px;
    span:first-child {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.5px;
      color: #232323;
    }
    span:last-child {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.5px;
      color: #999999;
    }
  }
}

.eventCategory {
  display: flex;
  height: 40px;
  padding-left: 10px;

  border-bottom: 1px solid #eeeeee;
  li {
    padding: 0 10px;
    height: 100%;
    // background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.5px;
    color: #999999;
    &.hit {
      font-weight: 700;
      color: #141736;
    }
  }
}

.eventList {
  min-height: 500px;
  background-color: white;
  li {
    padding: 25px 21px;
    background-color: white;
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
    margin-bottom: 10px;
    cursor: pointer;
    &:first-child {
      border-top: none;
    }
    &:last-child {
      margin-bottom: 0;
    }
    .thumbnailWrap {
      border-radius: 10px;
      overflow: hidden;
      img {
        width: 100%;
        aspect-ratio: 81/40;
        object-fit: cover;
      }
    }
    .eventInfoWrap {
      .writeInfo {
        display: flex;
        justify-content: flex-end;
        gap: 11px;
        span {
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          letter-spacing: -0.5px;
          color: #cccccc;
          position: relative;
          display: inline-block;
          padding: 6px 0;
          &:last-child::after {
            display: none;
          }
          &::after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            right: -6px;
            transform: translateY(-50%);
            width: 1px;
            height: 10.5px;
            background-color: #cccccc;
          }
        }
      }
      .isProgressing {
        width: 80px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #121842;
        border-radius: 100px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.5px;
        color: #141736;
        margin-bottom: 10px;
        box-sizing: border-box;
        &.isOff {
          border: none;
          background: #f6f7f9;
          color: #999999;
        }
      }
      .title {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.5px;
        color: #232323;
      }
    }
  }
}
