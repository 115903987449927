.totalScoreWrap {
  border-top: 10px solid #f8f9fd;
  border-bottom: 10px solid #f8f9fd;
  padding: 32px 20px 64px;
  &.isMobile {
    padding: 0 20px 32px;
    .title {
      padding: 16px 0px;
      div {
        padding: 0px 28.5px;
        color: #131c31;
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        display: flex;
        align-items: center;
        border-radius: 10px;
        border: 1px solid #d1d0d5;
        flex-grow: 0;
        @media screen and (max-width: 360px) {
          font-size: 15px;
          white-space: nowrap;
          padding: 0px 5px;
        }
      }
    }
  }
  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #131c31;
    padding: 16px 0px 32px 0px;
    display: flex;
    justify-content: space-between;
    line-height: 49px;
    cursor: pointer;
    @media screen and (max-width: 357px) {
      white-space: nowrap;
      line-height: 40px;
    }
    div {
      flex-grow: 0.74;
      text-align: center;
      color: #131c31;
      font-size: 20px;
      font-weight: 500;
      border-radius: 10px;
      border: 1px solid #d1d0d5;
    }
  }
  .avgWrap {
    display: flex;
    align-items: center;
    gap: 37px;
    .left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-grow: 0.65;
      & > span {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: var(--theme-font-color);
        padding: 10px 0;
        display: inline-block;
      }
      & > div {
        padding: 10px 0;
        display: flex;
        align-items: center;
        gap: 4px;
        img {
          width: 48px;
          height: 48px;
        }
        span {
          font-weight: 600;
          font-size: 48px;
          line-height: 58px;
          color: var(--theme-font-color);
        }
      }
    }
    .right {
      flex-grow: 1.35;
      ul {
        display: flex;
        flex-direction: column;
        gap: 10px;
        li {
          width: 100%;
          height: 49px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #f0f1f5;
          border-radius: 5px;
          padding: 0 10px;
          box-sizing: border-box;
          position: relative;
          .title {
            font-weight: 600;
            font-size: 24px;
            color: var(--theme-font-color);
            position: relative;
            z-index: 1;
          }
          .selectedPercent {
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            color: var(--theme-font-color);
            position: relative;
            z-index: 1;
          }
          .gauge {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            border-radius: 5px;
          }
        }
      }
    }
  }
  .avgWrapMobile {
    .scoreWrap {
      display: flex;
      background: #f9f9fb;
      border: 1px solid #e9e9e9;
      border-radius: 5px;
      position: relative;
      &::after {
        content: '';
        display: block;
        width: 1px;
        position: absolute;
        top: 10px;
        bottom: 10px;
        left: 50%;
        background: #e6e6e6;
      }
      & > div:first-child {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 24px 0;
        & > span:first-child {
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          color: var(--theme-font-color);
          padding: 5px 0;
        }
        & > span:last-child {
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          color: var(--theme-font-color);
        }
      }
      & > div:last-child {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        img {
          width: 30px;
          height: 30px;
        }
        span {
          font-weight: 600;
          font-size: 32px;
          line-height: 38px;
          color: var(--theme-font-color);
        }
      }
    }
    .simpleReviewWrap {
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin-top: 20px;
      & > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 42px;
        background: #f0f1f5;
        border-radius: 5px;
        position: relative;
        .guage {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          border-radius: 5px;
          z-index: 1;
        }
        & > span:first-child {
          font-weight: 600;
          font-size: 15px;
          line-height: 18px;
          color: var(--theme-font-color);
          padding: 0 5px;
          position: relative;
          z-index: 2;
        }
        & > span:nth-child(2) {
          font-weight: 600;
          font-size: 15px;
          line-height: 18px;
          color: var(--theme-font-color);
          padding: 0 3px;
          position: relative;
          z-index: 2;
        }
      }
    }
  }
}
.photoReview {
  padding-left: 20px;
  padding-bottom: 32px;
  &.isMobile {
    .title {
      margin-bottom: 32px;
    }
  }
  .title {
    padding: 15px 10px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: var(--theme-font-color);
    display: inline-block;
  }
  .photoReviewSlider {
    position: relative;
    // min-height: 200px;
  }
}
.photoReviewSecond {
  padding: 32px 20px;
  border-bottom: 10px solid #f8f9fd;
  & > span {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: var(--theme-font-color);
    display: inline-block;
    padding: 10px;
    margin: 0 0 32px;
  }
  .photoList {
    display: flex;
    gap: 14px;
    position: relative;
    // min-height: 200px;
    li {
      // flex-grow: 1;
      cursor: pointer;
      width: calc(calc(100% - 56px) / 5);
      position: relative;
      img {
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
      }
      .imageCount {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 34px;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #ffffff;
        background: rgba(19, 28, 49, 0.6);
      }
    }
  }
}

.normalReview {
  padding-bottom: 64px;
  &.isMobile {
    padding-bottom: 16px;
  }
  .head {
    background: #f8f9fd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 52px;
    & > span {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: var(--theme-font-color);
      padding-left: 10px;
    }
    .moreBtn {
      display: flex;
      align-items: center;
      cursor: pointer;
      height: 100%;
      span {
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        color: var(--theme-font-color);
      }
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
  .normalReviewList {
    position: relative;
    // min-height: 200px;
  }
}
.normalReviewSecond {
  margin-bottom: 64px;
  &.isMobile {
    margin-bottom: 16px;
  }
  & > span {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    display: inline-block;
    padding: 10px;
    color: var(--theme-font-color);
    margin: 32px 0;
  }
  .normalReviewList {
    position: relative;
    // min-height: 200px;
  }
}
.writeReviewBtnWrap {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 32px;
  .writeReviewBtn {
    background: #ffffff;
    border: 1px solid var(--theme-color);
    border-radius: 10px;
    padding: 10px 27px;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: var(--theme-font-color);
    width: fit-content;
    margin: 0 20px 0;
    cursor: pointer;
  }
}

.paginationAndBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 64px;
  min-height: 40px;
  .pagination {
    display: flex;
    align-items: center;
    .number {
      display: flex;
      span {
        width: 32px;
        height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.5px;
        color: #999999;
        cursor: pointer;
        &.hit {
          font-weight: 600;
          line-height: 17px;
          color: #232323;
          position: relative;
          &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 2px;
            left: 6px;
            right: 6px;
            height: 2px;
            background-color: #232323;
          }
        }
      }
    }
    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
  .writeReviewBtn {
    background: #ffffff;
    border: 1px solid var(--theme-color);
    border-radius: 10px;
    padding: 10px 27px;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: var(--theme-font-color);
    width: fit-content;
    margin: 0 20px 0;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0%;
    transform: translate(0%, -50%);
  }
}

.reviewDetailModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.7);
  .reviewDetailContent {
    position: absolute;
    top: 50%;
    left: 15px;
    right: 15px;
    transform: translateY(-50%);
    .closeBtn {
      display: block;
      width: fit-content;
      margin-left: auto;
      padding: 14.5px 21px;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      color: #ffffff;
      cursor: pointer;
    }
    .imageSliderWrap {
      position: relative;
      .swipeBtn {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 50px;
        height: 50px;
        z-index: 2;
        cursor: pointer;
        &.right {
          left: auto;
          right: 0;
        }
      }
      .reviewImage {
        width: 100%;
        aspect-ratio: 1/0.91;
        object-fit: cover;
      }
    }
    .reviewInfoWrap {
      background-color: white;
      padding: 23px 0 60px;
      .userInfoWrap {
        display: flex;
        gap: 4px;
        .userId {
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          color: var(--theme-font-color);
          padding: 4px 10px;
          display: inline-block;
        }
        .userInfo {
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          color: #abb1bb;
          padding: 4px 10px;
          display: inline-block;
        }
      }
      .starWrap {
        display: flex;
        padding: 4px 10px;
        img {
          width: 22px;
          height: 22px;
        }
      }
      .content {
        padding: 20px 13px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: var(--theme-font-color);
      }
    }
  }
}

:global(.pc) {
  .reviewDetailModal {
    width: 850px;
    left: 50%;
    transform: translateX(-50%);
  }
  .reviewDetailContent {
    width: 596px;
    left: 50%;
    transform: translate(-50%, -50%);
    .swipeBtn {
      width: 69px !important;
      height: 69px !important;
    }
  }
}
