.header {
  padding: 13px 0 13px 21px;
  background: #f6f7f9;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: #232323;
}
.noticeList {
  // padding-bottom: 30vh;
  min-height: 30vh;
}
.noData {
  text-align: center;
  color: #6e6d79;
  font-size: 18px;
  padding: 150px 0;
}
