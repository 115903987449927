.container {
  background: url('../../assets/loginBanner.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  min-height: 100vh;
  padding: 60px 21px 146px;
  box-sizing: border-box;
  &.isMobile {
    padding-top: 57px;
  }
}
.backBtn {
  margin-bottom: 87px;
  img {
    width: 20px;
    height: 16px;
  }
}
.contentWrap {
  max-width: 333px;
  margin: 0 auto;
  .title {
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: -0.5px;
    color: #ffffff;
    text-align: center;
    padding: 10px 0;
  }
  .kakaoEventText {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #ffffff;
    padding: 11px 0;
    text-align: center;
  }
  .loginForm {
    margin: 28px 0 13px;
    display: flex;
    flex-direction: column;
    .kakaoLoginBtn {
      background: #fee500;
      border-radius: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 55px;
      cursor: pointer;
      img {
        position: absolute;
        width: 48px;
        height: 48px;
        top: 50%;
        left: 14px;
        transform: translateY(-50%);
      }
      span {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #1c1c1e;
      }
    }
    .or {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 9px;
      padding: 37px 0 38px;
      div {
        flex-grow: 1;
        height: 1px;
        background: rgba(255, 255, 255, 0.5);
      }
      span {
        font-weight: 300;
        font-size: 15px;
        line-height: 18px;
        color: rgba(255, 255, 255, 0.5);
      }
    }
    .inputWrap {
      position: relative;
      margin-bottom: 13px;
      input {
        width: 100%;
        height: 55px;
        background: rgba(255, 255, 255, 0.7);
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
        border-radius: 27.5px;
        box-sizing: border-box;
        outline: none;
        border: none;
        padding-left: 57px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.5px;
        color: #141736;
        &::placeholder {
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: -0.5px;
          color: #141736;
        }
      }
      img {
        width: 19px;
        height: 20px;
        position: absolute;
        top: 50%;
        left: 29px;
        transform: translateY(-50%);
      }
    }
    .loginBtn {
      height: 55px;
      box-sizing: border-box;
      background: #141736;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
      border-radius: 27.5px;
      border: none;
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.5px;
      color: #ffffff;
      cursor: pointer;
    }
  }
  .menu {
    display: flex;
    justify-content: center;
    gap: 23px;
    padding: 10px 0;
    li {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.5px;
      color: #ffffff;
      position: relative;
      cursor: pointer;
      &:first-child::after {
        display: none;
      }
      &::after {
        content: 'l';
        display: block;
        position: absolute;
        left: -12px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
