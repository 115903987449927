.container {
  margin-bottom: 160px;
  & > h3 {
    display: flex;
    gap: 3px;
    align-items: center;
    padding: 10px 0;
    width: fit-content;
    cursor: pointer;
    span {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #1c1c1c;
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
  & > span {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.004em;
    color: #1c1c1c;
  }
}
.reviewSliderWrap {
  position: relative;
  min-height: 200px;
  .reviewSlider {
    margin: 42px 0 64px;
  }
  .reviewFilter {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(242, 242, 242, 0.67);
    backdrop-filter: blur(2.5px);
    border-radius: 10px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    & > p {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #1c1c1c;
      text-align: center;
      margin-bottom: 19px;
    }
    & > a {
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--theme-color);
      border-radius: 22px;
      width: 90px;
      height: 38px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.5px;
      color: #ffffff;
    }
  }
}
