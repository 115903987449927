.prevWrap {
  padding: 8px 21px;
  color: #131c31;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  span {
    color: #131c31;
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    margin-right: 14px;
  }
}
.referralTopWrap {
  background-color: #2e2e2e;
  position: relative;
  &.isDesktop {
    max-height: 270px;
  }
  &.isMobile {
    // min-height: 298px;
    // white-space: nowrap;
    max-height: 302px;
    @media screen and (max-width: 357px) {
      max-height: 350px;
    }
    .inviteWrap {
      padding: 50px 70px 0px 70px;
    }
  }
  .inviteWrap {
    text-align: center;
    padding: 50px 315px 0px 315px;
    margin-bottom: 16px;
    img {
      width: 40px;
      height: 40px;
      margin-bottom: 16px;
    }
    p {
      color: #fff;
      font-size: 20px;
      font-weight: 500;
    }
  }
  .referralMsg3 {
    display: flex;
    padding: 0px 140px;
    justify-content: space-around;
    margin-bottom: 50px;
    div {
      display: flex;
      gap: 8px;
      span {
        color: #ffffff;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        border: 1px solid #e5e5e5;
        padding: 3px 8px;
      }
      span:nth-child(2) {
        color: #d9d9d9;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        border: none;
      }
    }
    &.isMobile {
      padding: 0px 20px 0px 40px;
      display: inline-block;
      // white-space: nowrap;
      margin-bottom: 50px;
      text-align: center;
      div {
        margin-bottom: 10px;
      }
      div:nth-child(2) {
        margin: 0;
      }
      span {
        min-width: 42px;
        padding: 3px 8px;
      }
      @media screen and (max-width: 357px) {
        div {
          span {
            font-size: 12px;
            font-weight: 400;
            padding-top: 5px;
          }
          span:nth-child(2) {
            font-size: 12px;
            font-weight: 400;
            padding: 0px;
            text-align: left;
          }
        }
      }
    }
  }
  .referralWrap {
    padding: 0px 21px;
    &.isDesktop {
      display: flex;
      gap: 10px;
      padding: 0px 87px;
      div {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0px 24px;
        height: 56px;
      }
    }
    &.isMobile {
      @media screen and (max-width: 357px) {
        div {
          span {
            font-size: 13px;
            font-weight: 500;
          }
          .refCode {
            font-size: 13px;
            font-weight: 500;
          }
          .refCopy {
            img {
              width: 14px;
              height: 14px;
            }
            font-size: 13px;
            font-weight: 500;
          }
        }
      }
    }
    div {
      background-color: white;
      border-radius: 10px;
      margin-bottom: 10px;
      padding: 16px 24px;
      display: flex;
      justify-content: space-between;

      img {
        width: 24px;
        height: 24px;
      }
      span {
        color: #838a9b;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.5px;
      }
      .refCode {
        color: #131c31;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.5px;
        margin-right: 4px;
      }
      .refCopy {
        cursor: pointer;
        img {
          width: 14px;
          height: 14px;
        }
        color: #ff5656;
        font-size: 15px;
        font-weight: 500;
      }
    }
    div:nth-child(2) {
      margin-bottom: 16px;
    }
  }
}

.contentWrap {
  padding: 144px 21px 81px 21px;
  &.isDesktop {
    padding: 78px 87px 80px 87px;
  }
  background-color: #f6f7f9;
  .referralListWrap {
    margin-bottom: 16px;
    .referralListTitle {
      display: flex;
      justify-content: space-around;
      padding: 16px 24px;
      border-radius: 10px 10px 0px 0px;
      background-color: #535353;
      white-space: nowrap;
      &.isDesktop {
        span:nth-child(2) {
          min-width: 300px;
        }
      }
      span {
        color: #fff;
        font-size: 15px;
        font-weight: 400;
      }
    }
    .referralListInfo2 {
      padding: 0px 24px;
      background-color: #fff;
      &:last-child {
        border-radius: 0px 0px 10px 10px;
      }
      &.isDesktop {
        div {
          span:nth-child(1) {
            color: rgba(19, 28, 49, 0.5);
            font-size: 15px;
            font-weight: 400;
          }
          span:nth-child(2) {
            min-width: 300px;
            color: #131c31;
            font-size: 15px;
            font-weight: 400;
          }
        }
      }
      div {
        padding: 16px 0px;
        display: flex;
        border-bottom: 1px solid #e5e5e5;
        justify-content: space-around;
        &:last-child {
          border: none;
        }
        span {
          color: #ff5656;
          font-size: 15px;
          font-weight: 700;
        }
        span:nth-child(1) {
          // min-width: 100px;
          color: rgba(19, 28, 49, 0.5);
          font-size: 15px;
          font-weight: 400;
        }
        span:nth-child(2) {
          min-width: 100px;
          color: #131c31;
          font-size: 15px;
          font-weight: 400;
        }
      }
    }
  }
}

.paginationWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
  & > img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  & > ul {
    display: flex;
    li {
      width: 27px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: -0.5px;
      color: rgba(19, 28, 49, 0.3);
      cursor: pointer;
      &.hit {
        color: #131c31;
        font-weight: 600;
        position: relative;
        &::after {
          content: '';
          display: block;
          width: 20px;
          height: 1px;
          background-color: #131c31;
          position: absolute;
          bottom: -1px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}

.referralPointInviteWrap {
  display: flex;
  justify-content: space-evenly;
  background-color: white;
  padding: 40px 33px;
  &.isDesktop {
    padding: 50px 191px;
  }
  div {
    p {
      min-width: 154px;
      color: #666;
      text-align: center;
      font-size: 15px;
      font-weight: 400;
    }
    p:nth-child(2) {
      color: #131c31;
      text-align: center;
      font-size: 20px;
      font-weight: 500;
    }
  }
  div:nth-child(2) {
    border-right: 1px solid #e5e5e5;
    min-height: 37px;
  }
  @media screen and (max-width: 357px) {
    div {
      p {
        min-width: 100px;
      }
    }
  }
}
