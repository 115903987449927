.container {
  position: relative;
}
.processWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  // background: url('../../assets/processBg.png');
  background-size: cover;
  padding: 38px 0 69px;
  margin-bottom: 32px;
  & > h3 {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: #1c1c1c;
    padding: 10px 0;
    margin: 0;
  }
  & > p {
    padding: 4px 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #1c1c1c;
  }
  .hashtag {
    margin-top: 17px;
    display: flex;
    gap: 17px;
    li {
      padding: 8px 18px;
      background: #f8f9fd;
      border: 1px solid #e1e6ef;
      border-radius: 100px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.05em;
      color: #141736;
    }
  }
  .processContentWrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    margin-top: 39px;
    padding: 0 29px;
    box-sizing: border-box;
    & > div {
      width: calc(50% - 10px);
      aspect-ratio: 1/0.922;
      border-radius: 16px;
      box-sizing: border-box;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      background: #f8f9fd;
      border-radius: 16px;
      padding-bottom: 6px;
      box-sizing: border-box;
      &:first-child {
        padding-top: 39px;
        img {
          width: 145px;
          height: 132px;
        }
      }
      &:nth-child(2) {
        padding-top: 22px;
        img {
          width: 196px;
          height: 145px;
        }
      }
      &:nth-child(3) {
        padding-top: 30px;
        img {
          width: 142px;
          height: 144px;
        }
      }
      &:nth-child(4) {
        padding-top: 42px;
        img {
          width: 150px;
          height: 129px;
        }
      }
      .text {
        display: flex;
        flex-direction: column;
        span {
          font-weight: 700;
          font-size: 32px;
          line-height: 38px;
          letter-spacing: -0.5px;
          color: #1c1c1c;
          text-align: center;
        }
        h4 {
          font-weight: 700;
          font-size: 32px;
          line-height: 38px;
          text-align: center;
          letter-spacing: -0.5px;
          color: #1c1c1c;
          padding: 10px;
        }
        p {
          height: 56px;
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          display: flex;
          justify-content: center;
          // align-items: f;
          text-align: center;
          letter-spacing: -0.5px;
          color: #1c1c1c;
        }
      }
    }
  }
}
.prescriptionProductWrap {
  padding-top: 32px;
  & > h3 {
    display: flex;
    align-items: center;
    padding: 10px 21px;
    width: fit-content;
    cursor: pointer;
    span {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #1c1c1c;
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
  & > span {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.004em;
    color: #1c1c1c;
    display: inline-block;
    padding-bottom: 10px;
    padding-left: 21px;
  }
  .prescriptionProductSlider {
    padding-left: 21px;
    .prescriptionProductSlide {
      cursor: pointer;
      & > img {
        width: 100%;
        aspect-ratio: 1/1.2;
        object-fit: cover;
        border-radius: 16px;
      }
      .productInfoWrap {
        h3 {
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          color: #1c1c1c;
          padding: 10px 0;
        }
        .hashtagList {
          li {
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: -0.004em;
            color: rgba(19, 28, 49, 0.5);
          }
        }
      }
    }
  }
}
.normalProductWrap {
  margin-bottom: 160px;
  & > h3 {
    display: flex;
    gap: 3px;
    align-items: center;
    padding: 10px 0;
    width: fit-content;
    cursor: pointer;
    span {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #1c1c1c;
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
  & > span {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.004em;
    color: #1c1c1c;
    display: inline-block;
    padding-bottom: 10px;
  }
  .normalProductList {
    display: flex;
    margin-top: 16px;
    li {
      padding-bottom: 14px;
      cursor: pointer;
      width: 100%;
      .productThumbnailWrap {
        width: 100%;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          background: #fbfbfb;
          border-radius: 5px;
          width: 100%;
          object-fit: contain;
          aspect-ratio: 810/400;
        }
      }
      .productInfoWrap {
        h3 {
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: -0.004em;
          color: #1c1c1c;
          padding: 10px 0;
        }
        .hashtagList {
          padding-bottom: 10px;
          li {
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: -0.004em;
            color: rgba(19, 28, 49, 0.5);
          }
        }
      }
    }
  }
  .productSlideWrap {
    cursor: pointer;
    .productThumbnailWrap {
      img {
        background: #fbfbfb;
        border-radius: 10px;
        width: 333px;
        aspect-ratio: 1/1;
        object-fit: cover;
      }
    }
    .productInfoWrap {
      padding: 16px;
      .productInfoLeft {
        p {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
        .productInfoPrice {
          display: flex;
          gap: 4px;
          align-items: center;
          span:first-child {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
          }
          span:nth-child(2) {
            font-weight: 600;
            font-size: 10px;
            color: #999;
            text-decoration: line-through;
          }
        }
        .productInfoScore {
          display: flex;
          gap: 3px;
          align-items: center;
          img {
            width: 14px;
            height: 14px;
          }
          span {
            font-size: 12px;
          }
        }
      }
    }
  }
}
.allProductList {
  padding: 0 21px 64px;
  & > h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #1c1c1c;
    padding: 10px 0;
  }
  & > span {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.004em;
    color: #1c1c1c;
    display: inline-block;
    padding-bottom: 10px;
  }
  .productListWrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 37px;
    li {
      width: calc(50% - 19px);
      padding-bottom: 20px;
      cursor: pointer;
      .productThumbnailWrap {
        width: 100%;
        border: 1px solid #e5e5e5;
        border-radius: 5px;
        overflow: hidden;
        img {
          width: 100%;
          aspect-ratio: 1/1.2;
          object-fit: cover;
        }
      }
      .productInfoWrap {
        display: flex;
        flex-direction: column;
        & > h3 {
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: -0.004em;
          color: #1c1c1c;
          padding: 10px 0;
        }
        .discountRate {
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: -0.05em;
          color: #ff5656;
          display: inline-block;
          padding: 0.5px 0;
        }
        .price {
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: -0.05em;
          color: #1c1c1c;
          display: inline-block;
          padding: 1px 0;
        }
        .reviewScore {
          display: flex;
          align-items: center;
          gap: 3px;
          height: 20px;
          img {
            width: 14px;
            height: 14px;
          }
          span {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: -0.05em;
            color: #1c1c1c;
          }
        }
      }
    }
  }
  button {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: rgba(19, 28, 49, 0.5);
    margin: 0 auto;
    border: 1px solid #e5e5e5;
    background-color: white;
    border-radius: 45.5px;
    width: 157px;
    height: 50px;
    display: block;
    margin-top: 64px;
    cursor: pointer;
  }
}
.faqWrap {
  padding: 32px 21px 118px 21px;
  margin-bottom: 160px;
  background: #f6f4f8;
  & > h3 {
    display: flex;
    align-items: center;
    padding: 10px 0;
    width: fit-content;
    cursor: pointer;
    span {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #1c1c1c;
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
  & > span {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.004em;
    color: #1c1c1c;
    display: inline-block;
    padding-bottom: 10px;
  }
  & > ul {
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
.legitScript {
  position: relative;
  & > img {
    width: 100%;
    aspect-ratio: 1/0.327;
    object-fit: cover;
  }
  & > div {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 80px;
      height: 79px;
      object-fit: cover;
    }
    p:nth-child(2) {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #ffffff;
      padding: 17px 0 10px;
    }
    p:last-child {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      color: #ffffff;
    }
  }
}
.ondocInfo {
  position: relative;
  margin-bottom: 160px;
  .processSlider {
    position: relative;
    z-index: 1;
    cursor: pointer;
    .handleBtn {
      background-color: #e8e1e8;
      padding: 10px;
      border-radius: 50px;
      position: absolute;
      top: 80px;
      left: 32px;
      width: 30px;
      height: 30px;
      z-index: 1;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
      &.rightHandleBtn {
        left: unset;
        right: 32px;
      }
    }
    .processSlide1,
    .processSlide2,
    .processSlide3 {
      border-radius: 10px;
      display: flex;
      height: 210px;
      background: #f6f4f8;
      gap: 38px;
      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 12px;
        margin-left: 111px;
        h3 {
          font-weight: 700;
          font-size: 24px;
          line-height: 36px;
          color: var(--theme-color);
        }
        p {
          font-weight: 400;
          font-size: 15px;
          line-height: 21px;
          color: var(--theme-font-color);
        }
      }
      .processImageWrap {
        position: absolute;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .processSlide1 {
      .processImageWrap {
        width: 107px;
        height: 133px;
        top: 38px;
        right: 150px;
      }
    }
    .processSlide2 {
      .processImageWrap {
        width: 136px;
        height: 136px;
        top: 37px;
        right: 107px;
      }
    }
    .processSlide3 {
      .processImageWrap {
        width: 134px;
        height: 134px;
        top: 38px;
        right: 108px;
      }
    }
  }
  .paginationWrap {
    position: absolute;
    bottom: 20px;
    right: 25px;
    display: flex;
    gap: 4px;
    z-index: 1;
    li {
      width: 8px;
      height: 8px;
      background: var(--theme-color);
      border-radius: 4px;
      transition: width 0.2s linear;
      cursor: pointer;
      &.hit {
        background: var(--theme-color);
        width: 24px;
      }
    }
  }
}

.doctorInfoWrap {
  padding: 64px 21px;
  padding: 64px 0;
  margin: 64px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  // background: #f5f5f5;
  background-size: cover;
  & > h3 {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: #1c1c1c;
    padding: 10px 21px;
  }
  & > p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #1c1c1c;
    padding: 4px 21px;
  }
  .doctorSliderWrap {
    position: relative;
    width: 100%;
    display: flex;
    margin-top: 64px;
    .doctor {
      position: relative;
      width: 100%;
      height: 100%;
      background: #fff;
      border: 1px solid #dddddd;
      box-sizing: border-box;
      border-radius: 20px;
      overflow: hidden;
      .doctorImageWrap {
        position: absolute;
        bottom: -20px;
        right: -35px;
        width: 190px;
        height: 234px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: top;
        }
      }
      h4 {
        padding: 20px 15px 0 15px;
        font-family: 'Manrope';
        font-weight: 800;
        font-size: 20px;
        line-height: 27px;
        color: #1c1c1c;
      }
      h5 {
        padding: 0 15px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #1c1c1c;
        margin: 1px 0 24px;
      }
      ul {
        padding: 20px 5px 0px 15px;
        height: 225px;
        li {
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #1c1c1c;
        }
      }
    }
  }
}

.ondocService {
  margin-bottom: 160px;
  background: url('../../assets/ondocService.png');
  aspect-ratio: 850/308;
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  .bigText {
    font-weight: 600;
    font-size: 28px;
    line-height: 38px;
    color: var(--theme-color);
  }
  .smallText {
    font-weight: 300;
    font-size: 17px;
    line-height: 24px;
    color: #131c31;
  }
}

.serviceInfoWrap {
  padding: 0 0 20px;
  text-align: center;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
}
