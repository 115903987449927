.contentWrap {
  max-width: 333px;
  padding: 30px 21px 0;
  margin: 0 auto;
  min-height: 500px;
  .tabs {
    display: flex;
    li {
      width: 50%;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(19, 28, 49, 0.2);
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.5px;
      color: rgba(19, 28, 49, 0.2);
      cursor: pointer;
      &.hit {
        color: var(--theme-font-color);
        border-bottom: 1px solid var(--theme-color);
      }
    }
  }
}

.inputWrap {
  display: flex;
  flex-direction: column;
  & > span:first-child {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: var(--theme-font-color);
    display: inline-block;
    padding: 20px 0;
  }
  & > input {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: var(--theme-font-color);
    height: 48px;
    padding-left: 15px;
    &::placeholder {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: rgba(19, 28, 49, 0.2);
    }
  }
  .telInputWrap {
    display: flex;
    align-items: center;
    span {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: var(--theme-font-color);
      display: inline-block;
      padding: 0 5px;
    }
    input {
      min-width: 0;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: var(--theme-font-color);
      height: 48px;
      padding-left: 10px;
      width: 100%;
      &::placeholder {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: rgba(19, 28, 49, 0.2);
      }
    }
  }
  .emailInputWrap {
    display: flex;
    & > span {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--theme-font-color);
      width: 20px;
    }
    input {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: var(--theme-font-color);
      outline: none;
      padding-left: 10px;
      &::placeholder {
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: rgba(19, 28, 49, 0.2);
      }
    }
    & > input,
    & > .customSelect {
      width: calc(50% - 10px);
      background: #ffffff;
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      height: 48px;
      box-sizing: border-box;
    }
    & > .customSelect {
      position: relative;
      overflow: hidden;
      input {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border: none;
      }
      select {
        width: 100%;
        border: none;
        box-sizing: border-box;
        height: 100%;
        outline: none;
        border-left: none;
        border-right: none;
        position: absolute;
        top: 0;
        left: 0;
        appearance: none;
        padding-left: 10px;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: var(--theme-font-color);
      }
      img {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }
  }
}

.submitBtn {
  background: var(--theme-color);
  border-radius: 5px;
  height: 48px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #e5e5e5;
  margin-top: 30px;
}
.resultMessage {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: var(--theme-font-color);
  padding: 20px 0;
}
