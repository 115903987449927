.user {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px 44px;
  background-color: #f6f7f9;
  .mark {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .userNameWrap {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    padding: 5px 0;
    margin-bottom: 30px;
    span {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: -0.5px;
      color: #232323;
    }
    img {
      width: 21px;
      height: 21px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(calc(100% + 9px), -50%);
    }
  }
}

.menuList {
  display: flex;
  flex-wrap: wrap;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  width: 100%;
  margin: 0 20px;
  & > li {
    cursor: pointer;
  }
  .countMenu {
    width: 50%;
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 28px;
    border-bottom: 1px solid #eeeeee;
    position: relative;
    &:first-child::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 1px;
      height: 40px;
      background-color: #eeeeee;
    }
    & > div {
      display: flex;
      align-items: center;
      gap: 3px;
      img {
        width: 24px;
        height: 24px;
      }
      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.5px;
        color: #838a9b;
      }
    }
    & > span {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.5px;
      color: #000000;
    }
  }
  .referralMenu {
    width: 50%;
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    // gap: 8px;
    position: relative;
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 1px;
      height: 40px;
      background-color: #eeeeee;
    }
    &:last-child::after {
      display: none;
    }
    & > div {
      display: flex;
      align-items: center;
      gap: 3px;
      img {
        width: 24px;
        height: 24px;
      }
      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.5px;
        color: #838a9b;
      }
    }
    & > span {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.5px;
      color: #000000;
    }
    .copy {
      &.isMobile {
        margin-left: 8px;
      }
      color: #ff5656;
      font-size: 15px;
      font-weight: 500;
      img {
        width: 14px;
        height: 14px;
        margin-left: 4px;
      }
    }
  }
  .normalMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    height: 117px;
    position: relative;
    width: 20%;
    border-bottom: 1px solid #eeeeee;
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 1px;
      height: 60px;
      background-color: #eeeeee;
    }
    &:nth-child(7)::after {
      display: none;
    }
    &:last-child::after {
      display: none;
    }
    img {
      width: 24px;
      height: 24px;
      margin-bottom: 6px;
    }
    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.5px;
      color: #838a9b;
    }
  }
}

.challengeWrap {
  // background-color: #f6f7f9;
  &.isMobile {
    padding: 40px 33px 60px 33px;
  }
  padding: 50px 48px 81px 48px;
}

.title {
  &.isMobile {
    white-space: nowrap;
    span:nth-child(1) {
      color: #232323;
      font-size: 20px;
      font-weight: 700;
    }
    span:nth-child(2) {
      float: right;
      color: #131c31;
      font-size: 15px;
      font-weight: 400;
    }
  }
  span {
    color: #131c31;
    font-size: 15px;
    font-weight: 400;
  }
  h2 {
    margin-top: 8px;
    color: #232323;
    font-size: 24px;
    font-weight: 700;
  }
}

.weightForm {
  &.isMobile {
    margin-top: 30px;
  }
  margin-top: 50px;

  .weightInputWrap {
    display: flex;
    border: none;
    border-bottom: 1px solid #e5e5e5;
    align-items: center;
    input {
      border: none;
      // background-color: #f6f7f9;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 11px 0 11px 12px;
      flex-grow: 1;
    }
    input::placeholder {
      color: #abb1bb;
      font-size: 15px;
      font-weight: 400;
    }
    span {
      color: rgba(19, 28, 49, 0.5);
      font-size: 15px;
      font-weight: 500;
      margin: 0 10px;
    }
  }
  .inputBtn {
    margin-top: 20px;
    border-radius: 5px;
    background: var(--theme-color);
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: -0.5px;
    width: 100%;
    height: 54px;
    border: none;
  }
  .unableBtn {
    margin-top: 20px;
    border-radius: 5px;
    background: #999;
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: -0.5px;
    width: 100%;
    height: 54px;
    border: none;
  }
  .inputCheck {
    margin-top: 12px;
    color: #ff3e3e;
    font-size: 15px;
    font-weight: 400;
  }

  .bannerWrap {
    border-radius: 5px;
    background: var(--theme-color);
    margin-top: 20px;
    margin-bottom: -31px;
    position: relative;
    &.isMobile {
      height: 114px;
      max-height: 114px;
      margin-bottom: -40px;
      .bannerImg {
        position: absolute;
        top: 15px;
        right: 6px;
        img {
          width: 93px;
          height: 58px;
        }
      }

      .bannerTextOk {
        padding: 25px 24px 8px 22px;
        p {
          color: #d1b1c8;
          font-size: 16px;
          font-weight: 700;
        }
        span:nth-child(2) {
          color: #fff;
          font-size: 16px;
          font-weight: 700;
        }
        span:nth-child(3) {
          color: #d1b1c8;
          font-size: 16px;
          font-weight: 700;
        }
      }
      .bannerTextDate {
        padding: 0px 22px 25px 22px;
        white-space: nowrap;
        span:nth-child(1) {
          color: #d1b1c8;
          font-size: 15px;
          font-weight: 400;
        }
        span:nth-child(2) {
          color: #fff;
          font-size: 15px;
          font-weight: 400;
        }
      }
    }

    &.isDesktop {
      height: 114px;
      max-height: 114px;
      .bannerImg {
        position: absolute;
        top: 20px;
        right: 40px;
        img {
          width: 118px;
          height: 74px;
        }
      }
      .bannerTextOk {
        padding: 32px 40px 8px 40px;
        span:nth-child(1),
        span:nth-child(3) {
          color: #d1b1c8;
          font-size: 20px;
          font-weight: 700;
        }
        span:nth-child(2) {
          color: #fff;
          font-size: 20px;
          font-weight: 700;
        }
      }
      .bannerTextDate {
        margin: 0px 40px 32px 40px;
        span:nth-child(1) {
          color: #d1b1c8;
          font-size: 15px;
          font-weight: 400;
        }
        span:nth-child(2) {
          color: #fff;
          font-size: 15px;
          font-weight: 400;
        }
      }
    }
  }
}

.orderStatus {
  padding: 0 20px 10px;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  @media screen and (max-width: 320px) {
    padding-left: 0;
    padding-right: 0;
  }
  h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: #232323;
    padding: 18px 0;
  }
  .statusList {
    display: flex;
    .status {
      flex-grow: 1;
      flex-basis: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 11px;
      height: 70px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.5px;
      color: #999999;
      & > span {
        white-space: nowrap;
      }
      &.hit {
        color: #141736;
      }
    }
    & > img {
      width: 12px;
      height: 13px;
      position: relative;
      top: 14px;
    }
  }
  .orderGuide {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.5px;
    color: #999999;
    padding: 7px 0 0;
  }
}
.userOrderWrap {
  border-top: 10px solid #f6f7f9;
  h3 {
    border-top: 1px solid #efefef;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: #232323;
    padding: 18px 0 18px 21px;
  }
  .orderListWrap {
    display: flex;
    flex-direction: column;
    li {
      border-bottom: 1px solid #f9f9f9;
      &:last-child {
        border-bottom: none;
      }
    }
  }
  .moreOrderBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.5px;
    color: #999999;
    background-color: #f6f7f9;
    height: 60px;
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
    cursor: pointer;
  }
}

.event,
.faq {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 21px;
  border-bottom: 1px solid #f9f9f9;
  cursor: pointer;
  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.5px;
    color: #232323;
  }
  img {
    width: 21px;
    height: 21px;
  }
}
