.container {
  &.isMobile {
    min-height: auto;
    .contentWrap {
      .header {
        padding: 18px 21px;
        justify-content: space-between;
        height: 60px;
        div:last-child {
          width: 24px;
        }
      }
      .mainWrap {
        padding: 21px 21px 37px;
        &.globalMainWrap {
          padding: 21px;
        }
      }
      .btnWrap {
        position: static;
      }
    }
  }
  .contentWrap {
    position: relative;
    background: #f6f7f9;
    min-height: calc(100vh - 184px);
    .header {
      padding: 21px;
      display: flex;
      align-items: center;
      height: 70px;
      box-sizing: border-box;
      background: #fff;
      img {
        width: 24px;
        height: 24px;
      }
      span {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: #131c31;
      }
    }
    .mainWrap {
      padding: 21px;
      .addressModalWrap {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.4);
        z-index: 100;
        .addressModal {
          position: absolute;
          top: 50%;
          left: 21px;
          right: 21px;
          transform: translateY(-50%);
          .modalHeader {
            background-color: #131c31;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 11px;
            img {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
      .addrForm {
        padding: 16px;
        display: flex;
        gap: 16px;
        flex-direction: column;
        background: #fff;
        border-radius: 10px;
        border: 1px solid #e5e5e5;
        .addrLabelWrap {
          .inputWrap {
            input {
              margin-bottom: 8px;
            }
          }
          .inputWrap:last-child {
            input {
              margin-bottom: 0;
            }
          }
        }
        .labelWrap {
          .labelTitleWrap {
            padding: 0 0 8px 0;
            & > span:first-child {
              font-weight: 500;
              font-size: 15px;
              color: #ff5656;
            }
            & > span:last-child {
              font-weight: 500;
              font-size: 14px;
              color: #666;
            }
          }
          .inputWrap {
            &.validationError {
              input {
                border: 1px solid #ff5656;
              }
            }
          }
          .countryRightWrap {
            .countrySelectWrap {
              display: flex;
              border-radius: 5px;
              border: 1px solid #d9d9d9;
              background: #fff;
              justify-content: space-between;
              align-items: center;
              height: 48px;
              box-sizing: border-box;
              &.validationError {
                border: 1px solid #ff5656;
              }
              p {
                color: rgba(19, 28, 49, 0.2);
                font-weight: 400;
                font-size: 14px;
                padding: 10px;
              }
              img {
                width: 24px;
                height: 24px;
                margin-right: 10px;
              }
            }
            .countryListWrap {
              border: 1px solid #d9d9d9;
              border-radius: 0px 0px 8px 8px;
              div {
                padding: 0px 16px;
                p {
                  color: #131c31;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 20px;
                  padding: 10px 0;
                  border-bottom: 1px solid #f4f4f4;
                }
              }
            }
          }
          .customCheckBoxListWrap {
            display: flex;
            gap: 8px;
            .customCheckBoxWrap {
              width: calc(100% / 2);
              height: 48px;
              padding: 0 16px;
              display: flex;
              align-items: center;
              gap: 8px;
              border-radius: 5px;
              border: 1px solid #d9d9d9;
              box-sizing: border-box;
              cursor: pointer;
              &.hit {
                border: 2px solid var(--theme-color);
                background: #ffecfa;
                .customCheckBox {
                  background: #fff;
                  border: 1.5px solid var(--theme-color);
                  &::after {
                    background-color: var(--theme-color);
                  }
                }
                span {
                  color: #131c31;
                }
              }
              span {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #999;
              }
              .customCheckBox {
                box-sizing: border-box;
                width: 16px;
                height: 16px;
                background: white;
                border: 1.5px solid #e5e5e5;
                border-radius: 50%;
                position: relative;
                &::after {
                  content: '';
                  display: block;
                  width: 8px;
                  height: 8px;
                  border-radius: 50%;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
              }
            }
          }
          .addrText {
            padding: 4px 0;
            margin: -8px 0 8px 0;
            font-weight: 400;
            font-size: 11px;
            line-height: 16px;
            color: #999;
          }
          .lastAddrText {
            margin: -8px 0 0 0;
          }
          .inputWrap {
            display: flex;
            &.addrInputWrap {
              margin-bottom: 8px;
            }
            & > input {
              padding: 10px;
              width: 100%;
              height: 48px;
              box-sizing: border-box;
              border: 1px solid #d9d9d9;
              border-radius: 5px;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
            }
            & > input::placeholder {
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: #999;
            }
          }
          .addressSearch {
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            color: #e5e5e5;
            width: 106px;
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: var(--theme-color);
            border-radius: 0px 5px 5px 0px;
            cursor: pointer;
            flex-shrink: 0;
            position: relative;
          }
          .globalTelAlert {
            padding: 4px 0;
            font-weight: 400;
            font-size: 11px;
            line-height: 16px;
            color: #ff5656;
            .hit {
              position: relative;
              bottom: 1px;
              font-weight: 700;
            }
          }
        }
      }
    }
    .btnWrap {
      padding: 10px 21px;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 72px;
      box-sizing: border-box;
      background: #fff;
      &.globalBtnWrap {
        position: static;
      }
      .orderBtnWrap {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 52px;
        background: var(--theme-color);
        border-radius: 8px;
        span {
          font-weight: 600;
          font-size: 17px;
          line-height: normal;
          color: #fff;
        }
      }
    }
  }
}
