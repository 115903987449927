.container {
  box-sizing: border-box;
  overflow: hidden;
}
.askOutWrap {
  &.isOpen {
    background: #fff;
    border-radius: 22px 22px 0 0;
  }
}
.askWrap {
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // height: 38px;
  padding: 10px 10px 10px 12px;
  cursor: pointer;
  position: relative;
  border-radius: 22px;
  &::after {
    content: '';
    display: block;
    height: 100%;
    width: 0;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--theme-color);
    border-radius: 22px;
    transition: all 0.5s;
  }
  &.isOpen::after {
    width: 100%;
  }
  span {
    padding: 0 12px 0 0;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: var(--theme-font-color);
    position: relative;
    z-index: 1;
  }
  &.isOpen span {
    color: white;
  }
  img {
    width: 24px;
    height: 24px;
    position: relative;
    z-index: 1;
  }
}

.answerWrap {
  background-color: white;
  max-height: 0;
  transition: max-height 0.5s, padding 0.5s;
  // overflow: hidden;
  overflow: scroll;
  padding: 0 12px;
  border-radius: 22px;
  &.isOpen {
    border-radius: 0 0 22px 22px;
    padding: 10px 12px;
    max-height: 300px;
  }
  * {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--theme-font-color);
  }
}
