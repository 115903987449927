.container {
  z-index: 998;
  background-color: white;
}
.safeArea {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: env(safe-area-inset-top);
  background-color: white;
}
.header {
  position: fixed;
  top: env(safe-area-inset-top);
  left: 0;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  .backBtn {
    position: absolute;
    top: 50%;
    left: 13px;
    transform: translateY(-50%);
    img {
      width: 16px;
      height: 12px;
    }
  }
  span {
    font-weight: 700;
    font-size: 14px;
    color: #1c1c1e;
  }
}
.contentWrap {
  overflow: scroll;
  .sectionHeader {
    padding: 20px 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.5px;
    color: #232323;
  }
}
.deliveryLocationWrap {
  padding: 30px 15px;
  display: flex;
  gap: 18px;
  border-bottom: 10px solid #f8f9fd;
  .bar {
    width: 20px;
    background: #4d7eff;
    border-radius: 100px;
  }
  .deliveryLocation {
    display: flex;
    flex-direction: column;
    & > div {
      position: relative;
      &.hit::after {
        width: 16px;
        height: 16px;
        top: 1px;
        left: -36px;
        // background: url('../../assets/image/imsi/deliveryCheck.png') no-repeat
        //   white center center / 10px 7px;
      }
      &::after {
        position: absolute;
        top: 5px;
        left: -32px;
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        background-color: white;
        border-radius: 50%;
      }
    }
    .isPreparing {
      margin-top: 12px;
      margin-bottom: 74px;
    }
    .isDelivering {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      column-gap: 10px;
      .detailBtn {
        display: inline-block;
        width: 89px;
        height: 24px;
        background: #f8f9fd;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        cursor: pointer;
        span {
          font-weight: 400;
          font-size: 12px;
          letter-spacing: -0.5px;
          color: #505866;
        }
        img {
          width: 12px;
          height: 12px;
        }
      }
      .deliveryDetailWrap {
        width: 100%;
        padding: 18px 0 21px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        .locationInfo {
          display: flex;
          flex-direction: column;
          .location {
            font-weight: 400;
            font-size: 12px;
            letter-spacing: -0.5px;
            color: #232323;
          }
          .date {
            font-weight: 400;
            font-size: 12px;
            letter-spacing: -0.5px;
            color: #999999;
          }
        }
      }
      &.isClose {
        margin-bottom: 74px;
      }
      &.isOpen .detailBtn img {
        transform: rotate(180deg);
      }
    }
    .isComplete {
      display: flex;
      align-items: center;
      .deliveryedTime {
        font-weight: 400;
        font-size: 12px;
        letter-spacing: -0.5px;
        color: #999999;
        margin-left: 9px;
      }
    }
  }
}

.deliveryInfoWrap {
  .title {
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    padding: 20px 0 20px 15px;
    border-bottom: 1px solid #f8f9fd;
  }
  .deliveryInfo {
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    & > div {
      display: flex;
      position: relative;
      & > span:first-child {
        font-weight: 500;
        font-size: 16px;
        letter-spacing: -0.5px;
        color: #b1b8c0;
        width: 68px;
      }
      & > span:nth-child(2) {
        font-weight: 400;
        font-size: 16px;
        letter-spacing: -0.5px;
        color: #1c1c1e;
        flex-grow: 1;
      }
      & > button {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        padding: 5px 14px;
        background: #ffffff;
        border: 1px solid #e1e6ef;
        border-radius: 5px;
        font-weight: 300;
        font-size: 15px;
        letter-spacing: -0.5px;
        color: #232323;
        cursor: pointer;
      }
    }
  }
}
