.container {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  & > span:first-child {
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #232323;
  }
  & > span:last-child {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.5px;
    color: #999999;
  }
}
