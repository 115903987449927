.challengeSubmitModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.7);
  .challengeSubmitWrap {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    border-radius: 28px;
    background: #fff;
    width: 279px;
    height: 340px;
    .challengeTextOk {
      margin: 24px 24px 20px 24px;
      border-bottom: 1px solid #f3f3f3;
      div:nth-child(1) {
        p:nth-child(1) {
          color: #666;
          font-size: 10px;
          font-weight: 600;
          margin-bottom: 4px;
        }
        p:nth-child(2) {
          color: #232323;
          font-size: 20px;
          font-weight: 700;
        }
        span:nth-child(3) {
          color: var(--theme-color);
          font-size: 20px;
          font-weight: 700;
        }
        span:nth-child(4) {
          color: #232323;
          font-size: 20px;
          font-weight: 700;
        }
      }
      div:nth-child(2) {
        margin-top: 8px;
        margin-bottom: 20px;
        white-space: nowrap;
        span:nth-child(1) {
          color: #666;
          font-size: 15px;
          font-weight: 400;
        }
        span:nth-child(2) {
          color: #131c31;
          font-size: 15px;
          font-weight: 400;
        }
      }
    }

    .challengeSubmitImg1 {
      width: 134px;
      height: 134px;
      margin: -365px 0px 0px 185px;
    }
    .challengeSubmitImg2 {
      width: 103px;
      height: 87px;
      margin: 8px 65px;
    }
    .challengePointInfo {
      margin: 0px 24px 0px 23px;
      max-width: 235px;
      width: 235px;
      .challengePointText {
        span:nth-child(1) {
          color: var(--theme-color);
          font-size: 15px;
          font-weight: 600;
        }
        span:nth-child(2) {
          color: #232323;
          font-size: 15px;
          font-weight: 600;
        }
        span:nth-child(3) {
          color: #232323;
          font-size: 15px;
          font-weight: 600;
        }
      }
      .challengePointBtnWrap {
        display: flex;
        gap: 5px;
        button {
          min-width: 112px;
          height: 40px;
          padding: 5px 10px;
          background: var(--theme-color);
          color: #fff;
          border: none;
          font-size: 15px;
          font-weight: 500;
        }
        .closeBtn {
          background: #fff;
          color: #131c31;
          border: 1px solid #e5e5e5;
          // display: block;
          // width: fit-content;
          // margin-left: auto;
          // cursor: pointer;
        }
      }
    }
  }
}

:global(.pc) {
  .challengeSubmitModal {
    width: 850px;
    left: 50%;
    transform: translateX(-50%);
  }
  .challengeSubmitWrap {
    width: 279px;
    height: 340px;
    left: 50%;
    transform: translate(-50%, -50%);
    .challengeSubmitInfoWrap {
      margin-left: 25px;
      div {
        margin-right: 20px;
      }
    }
  }
}
