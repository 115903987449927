.container {
  &.isMobile {
    .contentWrap {
      min-height: auto;
      .header {
        padding: 18px 21px;
        justify-content: space-between;
        height: 60px;
        div:last-child {
          width: 24px;
        }
      }
      .mainWrap {
        padding: 21px 21px 131px;
      }
    }
  }
  .contentWrap {
    position: relative;
    background: #f6f7f9;
    min-height: calc(100vh - 184px);
    .header {
      padding: 21px;
      display: flex;
      align-items: center;
      height: 70px;
      box-sizing: border-box;
      background: #fff;
      img {
        width: 24px;
        height: 24px;
      }
      span {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: #131c31;
      }
    }
    .mainWrap {
      padding: 21px;
      display: flex;
      gap: 16px;
      flex-direction: column;
      .refundWrap {
        display: flex;
        gap: 16px;
        flex-direction: column;
        padding: 16px;
        border-radius: 10px;
        background: #fff;
        border: 1px solid #e5e5e5;
        & > div {
          display: flex;
          gap: 8px;
          flex-direction: column;
          .titleWrap {
            span:first-child {
              color: #ff5656;
            }
            span {
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: #666;
            }
          }
          .inputWrap {
            &.validationError {
              input {
                border: 1px solid #ff5656;
              }
            }
            input {
              padding-left: 10px;
              width: 100%;
              height: 48px;
              border-radius: 5px;
              border: 1px solid #d9d9d9;
              box-sizing: border-box;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
      .noticeWrap {
        display: flex;
        gap: 8px;
        flex-direction: column;
        img {
          width: 16px;
          height: 16px;
        }
        .hitText {
          span {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: var(--theme-color);
          }
          .hit {
            font-weight: 600;
          }
        }
        .greyText {
          span {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #999;
          }
        }
      }
    }
    .editBtnWrap {
      padding: 10px 21px;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 72px;
      box-sizing: border-box;
      background: #fff;
      .editBtn {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 52px;
        background: var(--theme-color);
        border-radius: 8px;
        span {
          font-weight: 600;
          font-size: 17px;
          line-height: normal;
          color: #fff;
        }
      }
    }
  }
}
