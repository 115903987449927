.container {
  background: #f6f7f9;
  padding: 0 20px;
  min-height: 100vh;
}
.title {
  padding: 42px 12px;
  display: flex;
  align-items: center;
  gap: 10px;
  img {
    width: 21px;
    height: 21px;
    cursor: pointer;
  }
  span {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: #232323;
  }
}
:global(.pc) .title span {
  font-size: 24px;
  line-height: 29px;
}
.selectWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  .selectBtn {
    display: flex;
    align-items: center;
    height: 100%;
    gap: 6px;
    cursor: pointer;
    .customCheckbox {
      width: 20px;
      height: 20px;
      box-sizing: border-box;
      background: #ffffff;
      border: 1.5px solid #e5e5e5;
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &.checked {
        background-color: #3559ed;
        border: 1.5px solid #3559ed;
        position: relative;
      }
      img {
        width: 12px;
        height: 9px;
      }
    }
    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      color: var(--theme-font-color);
    }
  }
  .deleteBtn {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    height: 100%;
    color: var(--theme-font-color);
    cursor: pointer;
  }
}

.productListWrap {
  display: flex;
  flex-direction: column;
  li {
    margin-bottom: 12px;
    .productContainer {
      background: #ffffff;
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      .checkBoxWrap {
        padding: 10px 13px;
        .customCheckbox {
          width: 20px;
          height: 20px;
          box-sizing: border-box;
          background: #ffffff;
          border: 1.5px solid #e5e5e5;
          border-radius: 3px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &.checked {
            background-color: #3559ed;
            border: 1.5px solid #3559ed;
            position: relative;
          }
          img {
            width: 12px;
            height: 9px;
          }
        }
      }
      .productInfoWrap {
        display: flex;
        // padding-bottom: 25px
        padding: 0 12px 25px;
        .productImageWrap {
          width: 96px;
          height: 96px;
          border: 1px solid #e5e5e5;
          border-radius: 5px;
          overflow: hidden;
          flex-shrink: 0;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .productInfo {
          display: flex;
          flex-direction: column;
          .productName {
            padding: 6px 10px;
            margin-bottom: 7px;
          }
          .optionWrap {
            padding: 0 10px;
            display: flex;
            // flex-wrap: wrap;
            column-gap: 20px;
            .option,
            span {
              padding: 4px 0px;
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
              color: rgba(19, 28, 49, 0.5);
            }
            span:last-child {
              white-space: nowrap;
            }
          }

          .totalWrap {
            padding: 6px 10px;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: rgba(19, 28, 49, 0.5);
            display: flex;
            gap: 6px;
            margin-top: 7px;
          }
        }
      }
      .deliveryPriceWrap {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.5px;
        color: #232323;
        height: 49px;
        border-top: 1px solid #e5e5e5;
      }
    }
  }
}
.totalPriceInfoWrap {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 25px 15px;
  h4 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: var(--theme-font-color);
    padding: 10px 0;
    margin-bottom: 20px;
  }
  .productPrice,
  .discountPrice,
  .deliveryPrice {
    padding: 0 5px;
    height: 38px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > span:first-child {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: -0.5px;
      color: rgba(19, 28, 49, 0.5);
    }
    & > span:last-child {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: var(--theme-font-color);
    }
  }
  .deliveryPrice {
    margin-bottom: 15px;
  }
  .totalPriceWrap {
    padding: 15px 6px 0 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #e5e5e5;
    & > span:first-child {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: -0.5px;
      color: rgba(19, 28, 49, 0.5);
    }
    & > span:last-child {
      font-weight: 700;
      font-size: 21px;
      line-height: 25px;
      color: var(--theme-font-color);
    }
  }
}
.buyBtnArea {
  padding-top: 4px;
  height: 68px;
  .buyBtnWrap {
    background-color: #f6f7f9;
    padding-top: 6px;
    padding-bottom: calc(env(safe-area-inset-bottom) + 6px);
    &.fixedBtn {
      position: fixed;
      bottom: 0;
      left: 20px;
      right: 20px;
    }
    .buyBtn {
      width: 100%;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--theme-color);
      border-radius: 5px;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
.right {
  padding-bottom: 45px;
}
:global(.pc) {
  .selectWrap {
    width: 492px;
  }
  .contentWrap {
    display: flex;
    gap: 10px;
    position: relative;
    .left {
      width: 492px;
    }
    .right {
      flex-grow: 1;
      position: sticky;
      top: 200px;
      height: fit-content;
    }
  }
}

.container input[type='checkbox'] {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  -webkit-appearance: none;
  appearance: none;
  background: #ffffff;
  border: 1.5px solid #e5e5e5;
  border-radius: 3px;
  &.checked {
    background-color: #3559ed;
    border: 1.5px solid #3559ed;
    position: relative;
    &::before {
      content: '';
      display: block;
      width: 5px;
      height: 7px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      left: 5px;
      top: 2px;
    }
  }
}
