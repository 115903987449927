.isMobile {
  padding: 0 21px;
  background-color: white;
  border-top: 1px solid #efefef;
  .orderDate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 42px;
    & > span {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.5px;
      color: #999999;
      padding-left: 10px;
      position: relative;
      &::after {
        position: absolute;
        content: '';
        top: 50%;
        left: 4px;
        transform: translateY(-50%);
        width: 2px;
        height: 2px;
        border-radius: 50%;
        background-color: #999999;
      }
    }
    & > div {
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.5px;
        color: #999999;
      }
      img {
        width: 17px;
        height: 17px;
      }
    }
  }
  .productWrap {
    display: flex;
    align-items: center;
    padding: 10px 0 20px;
    .productThumbWrap {
      width: 80px;
      height: 80px;
      border: 1px solid #e5e5e5;
      border-radius: 10px;
      overflow: hidden;
      cursor: pointer;
      flex-shrink: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .productInfoWrap {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 10px;
      gap: 1px;
      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.5px;
        color: #999999;
      }
    }
    .status {
      width: 80px;
      height: 30px;
      border: 1px solid #dc0000;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.5px;
      color: #dc0000;
    }
  }
}
.isPC {
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 16px;
  height: 160px;
  padding-right: 10px;
  &.secondDesign {
    border-radius: 0;
    border: none;
    border-top: 1px solid #efefef;
  }
  .orderInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 21%;
    span {
      padding: 4px 0;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: -0.5px;
      color: #999999;
    }
  }
  .productInfo {
    display: flex;
    align-items: center;
    flex-basis: 42%;
    .productThumbWrap {
      width: 80px;
      height: 80px;
      border: 1px solid #e5e5e5;
      border-radius: 10px;
      flex-shrink: 0;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
      }
    }
    span {
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.5px;
      color: var(--theme-font-color);
      padding-left: 11px;
      padding-right: 11px;
    }
  }
  .price {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.5px;
    color: #999999;
  }
  .statusWrap {
    display: flex;
    align-items: center;
    gap: 18px;
    margin-left: auto;
    .status {
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: -0.5px;
      color: #dc0000;
      border: 1px solid #dc0000;
      border-radius: 100px;
      width: 83px;
      height: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}
